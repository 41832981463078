import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static values = {
    clickOnLaunch: String
  }

  connect() {
    if (this.hasClickOnLaunchValue) {
      var oThis = this
      var intervalID = window.setInterval(function(time){
        var targetSelector = oThis.clickOnLaunchValue
        var clickTarget = $(targetSelector)

        if(clickTarget.length != 0) {
          window.clearInterval(intervalID)
          clickTarget.click()
        }
      }, 100)

    }
  }


}