import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tos", "tosError", "form", "promoterCheck"];
  static values = { user: Number, event: Number, displayImmediately: Boolean };

  connect() {
    this.setModalCloseEvent();
    if (this.hasDisplayImmediatelyValue && this.displayImmediatelyValue) {
      if (this.shouldDisplayForCurrentEvent()) this.show();
    }
  }

  submit(event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.hasTosTarget && !this.tosTarget.checked) {
      this.tosErrorTarget.classList.remove("error-hidden");
      return;
    }

    fetch(this.formTarget.action, {
      method: "POST",
      credentials: "same-origin",
      body: new FormData(this.formTarget),
    })
      .then((response) => response.text())
      .then((body) => {
        let json = JSON.parse(body);
        if (json.status == "ok") {
          window.location = json.redirect;
        } else if (json.status == "failed") {
          alert("Failed: " + json.message);
        }
      });

    $("#event-signup-confirmation-modal").modal("hide");
  }

  show(event) {
    if (event != undefined) {
      event.preventDefault();
    }

    fetch(this.data.get("url"))
      .then((response) => response.text())
      .then((html) => {
        document.getElementById("event_signup_modal_container").innerHTML =
          html;
        $("#event-signup-confirmation-modal").modal("show");
      });
  }

  setModalCloseEvent() {
    // keep the page tidy, you must use jquery for this
    $("#event_signup_modal_container").on("hidden.bs.modal", (e) => {
      document.getElementById("event_signup_modal_container").innerHTML = "";
    });
  }

  shouldDisplayForCurrentEvent() {
    if (window.location.search.includes("force_modal=1")) {
      return true;
    }

    var count = 0;
    if (localStorage.getItem(this.countKey())) {
      count = parseInt(localStorage.getItem(this.countKey()));
    } else {
      count = 0;
    }

    if (count < 2) {
      count = count += 1;
      localStorage.setItem(this.countKey(), count);
      return true;
    } else {
      console.log(
        "EventSignupModal - remains invisible because seen too many times. Clear local storage '" +
          this.countKey() +
          "' to test."
      );
      return false;
    }
  }

  countKey() {
    const user_id = this.userValue;
    const event_id = this.eventValue;
    return "user_event_modal_count" + user_id + "_" + event_id + "_v1";
  }
}
