import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["card"]
    static values = { url: String, hover: Boolean }

    show(event) {
      if (this.element != event.target) {
            return false
        }

        if (!this.hasCardTarget) {
            let controller = this
            fetch(this.urlValue)
                .then((r) => r.text())
                .then((html) => {
                  controller.showTooltip(html)
                });
        } else {
          if (this.hoverValue) {
            this.cardTarget.classList.remove("hidden");
          } else {
            $(this.cardTarget).modal('show')
          }
        }
    }

    showTooltip(html) {
      if (this.hoverValue) {
        this.showHoverTooltip(html)
      } else {
        this.showModalTooltip(html)
      }
    }

    showHoverTooltip(html) {
      console.log("showHoverTooltip()")

      const fragment = document
        .createRange()
        .createContextualFragment(html);

      this.element.appendChild(fragment);
      // console.log("append: " + this.urlValue)
      const popper = Popper.createPopper(this.element, this.cardTarget, {
        placement: 'bottom',
        padding: { top: 30, bottom: 30 },
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 10],
            },
          },
          {
            name: 'arrow',
            options: {
              padding: 10, // 5px from the edges of the popper
            },
          },

        ],
        onFirstUpdate: state => {
          // console.log('Popper positioned on', state.placement)
          this.cardTarget.classList.remove("hidden")
        }
      });

      // Sadly, the below is required to prevent graph tooltips from being displayed significantly below their items until the page is scrolled.
      // It may be due to the canvas size and dynamic nature of the page, but I'm not aware of a better solution.
      // James Cowlishaw, 2021/12/20
      setTimeout(function(){
        window.scrollBy(0, 0)
        popper.update()
      }, 0)
    }

    showModalTooltip(html) {
      console.log("showModalTooltip()")
      // This little dance is to allow us to aply the modal to the element we just appended to the DOM.
      // setTimeout is required to ensure it's been added before we attempt to JQuery the element.
      // The temp element is used as a container for the html passed in. It is not itself added to the DOM.
      const documentFragment = document.createDocumentFragment();

      var temp = document.createElement('div')
      temp.innerHTML = html
      while (temp.firstChild) {
        documentFragment.appendChild(temp.firstChild)
      }

      var element = documentFragment.children[0]
      $(this.element).append(element)
      setTimeout(function () {
        $(element).modal('show')
      })

    }

    hide(event) {
        event.stopPropagation()
        if (this.hasCardTarget) {
          if (this.hoverValue) {
            this.cardTarget.classList.add("hidden");
          } else {
            $(this.cardTarget).modal('hide')
          }
          this.cardTarget.remove();
        }
    }

    disconnect() {
        if (this.hasCargTarget) {
            // $(this.cardTarget).modal('hide')
            this.cardTarget.remove();
        }
    }
}