import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["ethnicity", "input"];

  update(event) {
    this.ethnicityTarget.value = event.target.value;
  }

  checked(event) {
    this.inputTarget.disabled = !event.target.checked;
    this.inputTarget.parentElement.classList.toggle("disabled");
    this.inputTarget.classList.toggle("disabled");
  }
}
