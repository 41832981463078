import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['slide', 'nextButton', 'retryButton', 'previousButton', 'correctMessage', 'modal', 'progressBar', 'finishButton']

   connect() {
    this.setProgressMinWidth();
    this.index = 1
    this.total_slides = parseInt(this.data.get('slide-count'))

    this.setModalCloseEvent();
    this.setLinkOpenInNewWindow()
  }

  answered(event) {
    this.disableQuestions();
    this.setSelectedAnswer(event.currentTarget);

    if (event.currentTarget.dataset.answer == 'true') {
      this.setNextStatus(true)
      this.retryButtonTarget.classList.add('hidden')
      this.nextButtonTarget.classList.remove('hidden')
      this.correctMessageTarget.classList.remove('hidden')
    } else {
      this.nextButtonTarget.classList.add('hidden')
      this.retryButtonTarget.classList.remove('hidden')
    }
  }

  navigate(event) {
    event.preventDefault()

    let index = this.getIndex(event.target)
    this.setPreviousStatus()
    this.setProgress()
    if (index == 0) return false;

    let url1 = this.data.get("url").replace("replace_id", this.index)
    let url = new URL(url1)
    let params = new URLSearchParams(url.search);
    params.set("direction", event.target.dataset.direction)
    url.search = params.toString()

    fetch(url)
      .then(response => response.text())
      .then(html => {
        this.slideTarget.outerHTML = html
        this.setLinkOpenInNewWindow()
        this.setNextStatus(false)
        window.scrollTo(0, 0);
      })
  }

  show(event) {
    event.preventDefault()
    let element = event.target.closest(".presented-course-content");

    this.modalTarget.innerHTML = '';

    fetch(element.dataset.url)
      .then(response => response.text())
      .then(html => {
        this.modalTarget.innerHTML = html
        history.pushState(null, null, element.dataset.url.split('?')[0])
        $('#course_modal_show').modal('show')
      })
  }

  retry(event)   {
    this.nextButtonTarget.classList.remove('hidden')
    this.retryButtonTarget.classList.add('hidden')

    this.resetQuestions()
    this.resetResponses()
    this.setNextStatus(false)
  }

  resetQuestions() {
    let list = document.getElementsByClassName('question');
    Array.prototype.forEach.call(list,
                         question_div => { this.resetQuestion(question_div); });
  }

  disableQuestions() {
    let list = document.getElementsByClassName('question');
    Array.prototype.forEach.call(list,
                         question_div => { this.disableQuestion(question_div); });
  }

  resetResponses() {
     // this.correctMessageTarget.classList.add('hidden')
    let list = document.getElementsByClassName('course-question-response');
    Array.prototype.forEach.call(list,
                         question_div => { question_div.classList.add('hidden'); });
  }

  setSelectedAnswer(target) {
    target.classList.add('selected-question');
    target.classList.remove('disabled-question');
    target.getElementsByClassName('course-question-response')[0].classList.remove('hidden');
  }

  disableQuestion(question) {
    question.classList.remove('selected-question');
    question.classList.add('disabled-question');
  }

  resetQuestion(question) {
    question.classList.remove('selected-question');
    question.classList.remove('disabled-question');
  }

  setNextStatus(enabled) {
    if (enabled == false && this.slideTarget.dataset.type == 'quiz') {
      this.nextButtonTarget.disabled = true
      this.nextButtonTarget.classList.add('btn-course-slide-disabled');
    } else if (this.slideTarget.dataset.type == 'final') {
      this.previousButtonTarget.classList.add('hidden')
    } else if (this.slideTarget.dataset.type == 'social') {
      this.nextButtonTarget.classList.add('hidden')
      this.finishButtonTarget.classList.remove('hidden')
    } else {
      this.nextButtonTarget.disabled = false
      this.nextButtonTarget.classList.remove('btn-course-slide-disabled');
    }
  }

  setPreviousStatus() {
    if (this.index < 2) {
      this.previousButtonTarget.disabled = true
      this.previousButtonTarget.classList.add('btn-course-slide-disabled');
    } else {
      this.previousButtonTarget.disabled = false
      this.previousButtonTarget.classList.remove('btn-course-slide-disabled');
    }
  }

  getIndex(element) {
    if (event.target.dataset.direction === 'back' && this.index == 1) {
      return 0
    } else if (event.target.dataset.direction === 'forward' && this.index == this.total_slides) {
      return 0
    } else if (event.target.dataset.direction === 'forward') {
      this.index++
    } else { this.index-- }

    return this.index
  }

  setProgressMinWidth() {

    if (document.getElementsByClassName('progress-bar-bike').length == 0) {
      return
    }

    if (screen.width > 799) {
      this.progressMinWidth = '4'
    } else if (screen.width > 500) {
      this.progressMinWidth = '10'
    } else {
      this.progressMinWidth = '15'
    }

    document.getElementsByClassName('progress-bar-bike')[0].style['width'] = `${this.progressMinWidth}%`
  }

  setProgress() {
    let width = this.progressMinWidth;
    if (this.data.get('slide-count') == this.index) {
      width = this.progressBarTarget.dataset['maximum']
    } else if (this.index > 1) {
      width = this.progressBarTarget.dataset['step'] * this.index
    }

    this.progressBarTarget.style['width'] = `${width}%`
  }

  setModalCloseEvent() {
    $("#course_modal_show").on('hidden.bs.modal', (e) => {
      e.preventDefault();
      let path = e.currentTarget.dataset.coursesUrl
      history.pushState(null, null, path.split('?')[0])
    });
  }

  setLinkOpenInNewWindow() {
    $(".course-content a").click(function(e) {
        $(this).attr("target","_blank");
    });
  }
}