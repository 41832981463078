import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.setupTabs();
    this.setupAddButton();
    this.checkInitialTab();
  }

  setupTabs() {
    const tabs = document.querySelectorAll('.nav-tabs a[data-toggle="tab"]');
    tabs.forEach((tab) => {
      tab.addEventListener("click", (event) => this.onTabClick(event));
    });
  }

  onTabClick(event) {
    const button = document.getElementById("js-ta-next");
    if (event.target.href.includes("#monitoring")) {
      button.value = "Create Announcement";
    } else if (event.target.href.includes("#targeting")) {
      button.value = "Save and Next";
    } else if (event.target.href.includes("#content")) {
      button.value = "Save and Next";
    }
  }

  setupAddButton() {
    const addButton = document.querySelector(".btn-add-response-option");
    addButton.addEventListener("click", () => {
      const hiddenResponses = document.querySelectorAll(
        ".question-response-fields.hide"
      );
      if (hiddenResponses.length > 0) {
        hiddenResponses[0].classList.remove("hide");
      }
    });
  }

  checkInitialTab() {
    if (window.location.hash === "#monitoring") {
      var button = document.getElementById("js-ta-next");
      if (button) {
        button.value = "Complete Announcement";
      }
    }
  }
}
