import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    inputId: String,
    maxChars: Number,
  };

  connect() {
    this.inputField = document.getElementById(this.inputIdValue);
    this.characterCountdown = this.element;

    if (this.inputField) {
      this.inputField.addEventListener("input", () =>
        this.updateCharacterCountdown()
      );
      this.updateCharacterCountdown();
    }
  }

  updateCharacterCountdown() {
    if (this.inputField && this.characterCountdown) {
      const remainingChars = this.maxCharsValue - this.inputField.value.length;
      this.characterCountdown.textContent = `${remainingChars} characters remaining`;
    }
  }

  disconnect() {
    if (this.inputField) {
      this.inputField.removeEventListener(
        "input",
        this.updateCharacterCountdown
      );
    }
  }
}
