import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static targets = ["file", "width", "height", "bytes"]

  connect() {
    var controller = this;
    this.fileTarget.addEventListener('change', function() {
      var file = controller.fileTarget.files[0];
      if (/\.(jpe?g|png|gif)$/i.test(file.name)) {
        var reader = new FileReader();
        reader.addEventListener("load", function () {
          var image = new Image();
          image.src = this.result;
          // image.src = this.result as string;
          image.addEventListener('load', function () {
            console.log(`height: ${this.height}, width: ${this.width}`);
            controller.widthTarget.value = this.width;
            controller.heightTarget.value = this.height;
            controller.bytesTarget.value = file.size;
          });

        }, false);

        reader.readAsDataURL(file);
      }

    })
  }



}