import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    formId: String,
  };

  connect() {
    this.form = document.querySelector(`.${this.formIdValue}`);
    if (this.form) {
      this.form.addEventListener("keydown", this.handleKeydown.bind(this));
    } else {
      console.error(`Form with ID #${this.formIdValue} not found.`);
    }
  }

  disconnect() {
    if (this.form) {
      this.form.removeEventListener("keydown", this.handleKeydown.bind(this));
    }
  }

  handleKeydown(event) {
    if (event.key === "Enter" && event.target.type !== "submit") {
      event.preventDefault();
    }
  }
}
