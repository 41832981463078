import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['modal']

  connect() {
    this.setModalCloseEvent();
  }

  show(event) {
    event.preventDefault()
    let element = event.target.closest(".presented-story-content");

    this.modalTarget.innerHTML = '';

    fetch(element.dataset.url)
      .then(response => response.text())
      .then(html => {
        this.modalTarget.innerHTML = html
        history.pushState(null, null, element.dataset.url.split('?')[0])
        $('#story-modal-show').modal('show')
        this.setModalCloseEvent();
      })
  }

  showComments(event) {
    event.preventDefault()
    let element = event.target.closest(".presented-story-body").getElementsByClassName('story-comments')[0]
    $(element).slideDown();
  }

  setModalCloseEvent() {
    $("#story-modal-show").on('hidden.bs.modal', (e) => {
      e.preventDefault();
      let path = e.currentTarget.dataset.storiesUrl
      history.pushState(null, null, path.split('?')[0])
    });
  }
}