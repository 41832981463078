import { Controller } from "@hotwired/stimulus"
import { ApplicationController, useDebounce } from 'stimulus-use'
import {latLngToCell, h3ToGeoBoundary} from "h3-js"

export default class extends Controller {
  static debounces = ['changeTrip']

  static values = {
    trip: String
  }

  static targets = ["trip", "features", "original", "matched", "statusBar", "h3Indexes", "coords"]

  connect() {
    useDebounce(this, { wait: 1000 })

    this.unrated_colour = '#7D7B80'
    // if (window.location.hostname == "comforted.local") {
    // this.baseUrl = "http://localhost:3001"
    // } else {
      this.baseUrl = "https://journey.lovetoride.net"
    // }

    mapboxgl.accessToken = 'pk.eyJ1IjoiY29ycmVjdGVkdGltZSIsImEiOiJZQWpHMy1JIn0.wtBkQp-NkyRbRdcot5o-9g';
    this.map = new mapboxgl.Map({
      container: 'map', // container ID
      style: 'mapbox://styles/mapbox/light-v11',
      center: [-3.22245605, 51.529670126], // starting position [lng, lat]
      zoom: 14, // starting zoom
      transformRequest: (url, resourceType) => {
        if (resourceType === 'Tile' && url.indexOf('http://localhost') > -1) {
          return {
            url: url.replace('http', 'http'),
            headers: {'Authorization': 'Bearer 1234567890'}//,
          };
        }
      }
    });

    let that = this
    this.map.on('load', () => {
      this.map.resize();
      if(this.hasTripValue) {
        this.initTripView()
      }
      this.map.on('mouseenter', 'trip_layer', (e) => {
        this.updateStatusBar(e.lngLat, e.features[0].properties)
      });
      this.map.on('mouseenter', 'features_layer', (e) => {
        this.updateStatusBar(e.lngLat, e.features[0].properties)
      });
      this.map.on('click', (e) => {
        this.showHex(e.lngLat)
      });

      this.displayH3Cells()
      this.displayPoints()

    })

  }

  displayPoints() {


    if (this.map.getSource('points')) {
      this.map.removeLayer('points')
      this.map.removeLayer('points-outline')
      this.map.removeSource('points')
    }

    let geojson = this.coordsTarget.value
    if (geojson == "") {
      return
    }

    // Add a data source containing GeoJSON data.
    this.map.addSource('points', {
      'type': 'geojson',
      'data': JSON.parse(geojson)
    });

    // Add a new layer to visualize the polygon.
    this.map.addLayer({
      'id': 'points',
      'type': 'circle',
      'source': 'points', // reference the data source
      'layout': {},
      'paint': {
        'circle-color': '#ff8000', // orange color fill
        'circle-opacity': 0.25
      }
    });

  }
  displayH3Cells() {
    var features = []
    let indexes_text = this.h3IndexesTarget.value
    indexes_text.split(/\W/).forEach((index) => {
      console.log(index)
      let coords = h3.cellToBoundary(BigInt(index).toString(16) , true)
      features.push({
          'type': 'Feature',
          'geometry': {
            'type': 'Polygon',
            'coordinates': [coords]
          }
        })
    })

    // console.log(features)

    let geojson = {
      "type": "FeatureCollection",
      "features": features
    }

    if (this.map.getSource('cells')) {
      this.map.removeLayer('cells')
      this.map.removeLayer('cells-outline')
      this.map.removeSource('cells')
    }

    // Add a data source containing GeoJSON data.
    this.map.addSource('cells', {
      'type': 'geojson',
      'data': geojson
    });

    // Add a new layer to visualize the polygon.
    this.map.addLayer({
      'id': 'cells',
      'type': 'fill',
      'source': 'cells', // reference the data source
      'layout': {},
      'paint': {
        'fill-color': '#ff8000', // orange color fill
        'fill-opacity': 0.25
      }
    });
    // Add a black outline around the polygon.
    this.map.addLayer({
      'id': 'cells-outline',
      'type': 'line',
      'source': 'cells',
      'layout': {},
      'paint': {
        'line-color': '#000',
        'line-width': 1,
        'line-opacity': 0.25
      }
    });
  }

  showHex(lngLat) {
    console.log(`lng: ${lngLat.lng}, lat: ${lngLat.lat}`)
    let cell = latLngToCell(lngLat.lat, lngLat.lng, 12)
    console.log(`H3 cell: ${cell}`)

    this.updateStatusBar(lngLat, {h3_cell: cell})

    let coords = h3.cellToBoundary(cell, true)

    let geojson = {
      'type': 'Feature',
      'geometry': {
        'type': 'Polygon',
        'coordinates': [coords]
      }
    }

    if (this.map.getSource('cell')) {
      this.map.removeLayer('cell')
      this.map.removeLayer('cell-outline')
      this.map.removeSource('cell')
    }

    // Add a data source containing GeoJSON data.
    this.map.addSource('cell', {
      'type': 'geojson',
      'data': geojson
    });

    // Add a new layer to visualize the polygon.
    this.map.addLayer({
      'id': 'cell',
      'type': 'fill',
      'source': 'cell', // reference the data source
      'layout': {},
      'paint': {
        'fill-color': '#0080ff', // blue color fill
        'fill-opacity': 0.5
      }
    });
    // Add a black outline around the polygon.
    this.map.addLayer({
      'id': 'cell-outline',
      'type': 'line',
      'source': 'cell',
      'layout': {},
      'paint': {
        'line-color': '#000',
        'line-width': 3
      }
    });
  }

  updateStatusBar(lngLat, properties) {
    if (this.hasStatusBarTarget) {
      var parts = [`${lngLat} -`]

      if (properties.segment_id != null) {
        parts = parts.concat(`<a target="_blank" href="https://metabase.lovetoride.net/question/1123-segment-debug?segment_id=${properties.segment_id}">Segment ${properties.segment_id}</a>`)
      }
      if (properties.osm_id != null) {
        parts = parts.concat(`<a target="_blank" href="https://www.openstreetmap.org/way/${properties.osm_id}">From OSM ${properties.osm_id}</a>`)
      }
      if (properties.rating_id != null) {
        parts = parts.concat(`&nbsp;<a target="_blank" href="https://metabase.lovetoride.net/question/1124-ratings-debug?rating_id=${properties.rating_id}" class="rating-${properties.rating_value}">Rating ${properties.rating_id}</a>`)
      }
      if (properties.h3_cell != null) {
        let num = BigInt("0x" + properties.h3_cell)
        // let num = parseInt(Number(`0x${properties.h3_cell}`), 10)
        parts = parts.concat(`&nbsp;<a target="_blank" href="https://metabase.lovetoride.net/question/1128-segments-in-h3-index?h3_index=${num}">H3 Cell ${properties.h3_cell} (${num})</a>`)
      }

      let html = parts.join(' ')
      this.statusBarTarget.innerHTML = html
    }
  }


  changeTrip(event) {
    this.tripValue = event.target.value
    if(this.hasTripValue && this.map != null) {
      this.cleanLayers()
      this.initTripView()
      let url = new URL(window.location);
      url.searchParams.set(event.target.dataset.query, this.tripValue);
      window.history.replaceState({}, '', url);
    }
  }

  showLayers() {
    let url = new URL(window.location);
    for (let [key, value] of url.searchParams.entries()) {
      this.setLayerVisibility(key, value)
    }
  }

  setLayerVisibility(key, value) {
    let layerName = `${key}_layer`
    let sourceName = `${key}_source`
    if(value == "false") {
      if(this.map.getLayer(layerName)) {
        this.map.removeLayer(layerName)
        this.map.removeSource(sourceName)
      }
    } else {
      if(this.map.getLayer(layerName) == null) {
        var slot = null
        if(this.map.getLayer("trip_layer") != null) {
          slot = "trip_layer"
        } else {
          slot = this.getFirstSymbolId()
        }
        var width = 4
        let target = this.targets.findTarget(key)
        if(target != null && "width" in target.dataset) {
          width = parseFloat(target.dataset.width)
        }

        this.insertLayer(layerName, this.tripValue, this.tileParams(), slot, width)
        // this.insertLayer(layerName, this.tripValue, this.tileParams(), slot, parseFloat(event.target.dataset.width) || 4)
      }
    }

  }
  toggleLayer(event) {
    var layer_name = event.target.id + "_layer"
    var source_name = event.target.id + "_source"

    let url = new URL(window.location);
    url.searchParams.set(event.target.dataset.query, event.target.checked);
    if(event.target.checked == false) {
      if(this.map.getLayer(layer_name)) {
        this.map.removeLayer(layer_name)
        this.map.removeSource(source_name)
      }
    } else {
      url.searchParams.set(event.target.dataset.query, true);
      if(this.map.getLayer(layer_name) == null) {
        var slot = null
        if(this.map.getLayer("trip_layer") != null) {
          slot = "trip_layer"
        } else {
          slot = this.getFirstSymbolId()
        }
        this.insertLayer(layer_name, this.tripValue, this.tileParams(), slot, parseFloat(event.target.dataset.width) || 4)
      }
    }
    window.history.replaceState({}, '', url);
  }

  cleanLayers() {
    if (this.map.getLayer("trip_layer") != null) {
      this.map.removeLayer("trip_layer")
      this.map.removeSource("trip_source")
    }
  }

  async getBounds() {
    const response = await fetch(this.baseUrl + "/api/ratings?trip_id=" + this.tripValue);
    return response.json();
  }

  tileParams() {
    let params = new URLSearchParams(document.location.search);

    var max_age = params.get("max_age");
    if (max_age == null) {
      max_age = 3600
    }
    var tile_params = '?max_age=' + max_age
    return tile_params
  }

  initTripView() {
    this.fitBoundsToTrip()
    this.showLayers()
    // this.insertLayer("trip_layer", this.tripValue, this.tileParams(), this.getFirstSymbolId(), 4)
  }

  fitBoundsToTrip() {
    this.getBounds().then((json) => {
      if (json.data.tile) {
        let bounds = json.data.tile.bounds;
        this.map.fitBounds([[bounds[0], bounds[1]], [bounds[2], bounds[3]]], {animate: false, padding: 10})
      }
    });
  }

  getFirstSymbolId() {
    const layers = this.map.getStyle().layers;
    let firstSymbolId;
    for (const layer of layers) {
      if (layer.type === 'symbol') {
        firstSymbolId = layer.id;
        break;
      }
    }
    return firstSymbolId
  }

  insertLayer(layer, trip_id, tile_params, firstSymbolId, lineWidth) {

    if (layer == "trip_layer") {
      this.map.addSource('trip_source', {
        type: 'vector',
        tiles: [this.baseUrl + '/api/tiles/' + trip_id + '/{z}/{x}/{y}.mvt' + tile_params],
      });

      this.map.addLayer(
        {
          'id': 'trip_layer', // Layer ID
          'type': 'line',
          'source': 'trip_source', // ID of the tile source created above
          'source-layer': 'default',
          'layout': {
            'line-cap': 'round',
            'line-join': 'round'
          },
          'paint': {
            // 'line-opacity': 0.5,
            'line-cap': 'butt',
            // 'line-gap-width': 2,
            'line-color': [
              'match', [
                "get", "rating_value"
              ],
              0, '#7D7B80', // unrated
              1, '#D1504C', // stressed
              2, '#E2B154', // uncomfortable
              3, '#1BB7B7', // comfortable
              4, '#98B446', // enjoyable
              '#7D7B80' // default
            ],
            'line-width': lineWidth
          }
        },
        firstSymbolId
      );
    }

    if (layer == "original_layer") {
      this.map.addSource('original_source', {
        type: 'vector',
        tiles: [this.baseUrl + '/api/tiles/' + trip_id + '/{z}/{x}/{y}.mvt' + tile_params + "&debug=o"],
      });

      this.map.addLayer(
        {
          'id': 'original_layer', // Layer ID
          'type': 'line',
          'source': 'original_source', // ID of the tile source created above
          'source-layer': 'default',
          'layout': {
            'line-cap': 'round',
            'line-join': 'round'
          },
          'paint': {
            'line-opacity': 0.5,
            'line-color': '#ff00ff',
            'line-width': lineWidth
          }
        },
        firstSymbolId
      );
    }

    if (layer == "matched_layer") {
      this.map.addSource('matched_source', {
        type: 'vector',
        tiles: [this.baseUrl + '/api/tiles/' + trip_id + '/{z}/{x}/{y}.mvt' + tile_params + "&debug=m"],
      });

      this.map.addLayer(
        {
          'id': 'matched_layer', // Layer ID
          'type': 'line',
          'source': 'matched_source', // ID of the tile source created above
          'source-layer': 'default',
          'layout': {
            'line-cap': 'round',
            'line-join': 'round'
          },
          'paint': {
            'line-opacity': 0.5,
            'line-color': '#00c0ff',
            'line-width': lineWidth
          }
        },
        firstSymbolId
      );

    }

    if (layer == "features_layer") {
      this.map.addSource('features_source', {
        type: 'vector',
        tiles: [this.baseUrl + '/api/tiles/' + trip_id + '/{z}/{x}/{y}.mvt' + tile_params + "&debug=f"],
        minzoom: 13,
        maxzoom: 15
      });

      this.map.addLayer(
        {
          'id': 'features_layer', // Layer ID
          'type': 'line',
          'source': 'features_source', // ID of the tile source created above
          'source-layer': 'default',
          'layout': {
            'line-cap': 'round',
            'line-join': 'round'
          },
          'paint': {
            'line-opacity': 1.0,
            'line-color': '#ffc6cd',
            'line-width': lineWidth
          }
        },
        firstSymbolId
      );

    }

    if (layer == "comfort_avg_layer") {
      this.map.addSource('comfort_avg_source', {
        type: 'vector',
        tiles: [this.baseUrl + '/api/tiles/' + trip_id + '/{z}/{x}/{y}.mvt' + tile_params + "&comfort=avg"],
        minzoom: 5,
        maxzoom: 15
      });

      const baseZoom = 18
      this.map.addLayer(
        {
          'id': 'comfort_avg_layer', // Layer ID
          'type': 'line',
          'source': 'comfort_avg_source', // ID of the tile source created above
          'source-layer': 'default',
          'layout': {
            'line-cap': 'butt',
            'line-gap': 10,
            'line-join': 'round',
            'line-sort-key': [
              '+', [
                "get", "rating_value"
              ]
            ]
          },
          'paint': {
            'line-color': [
              'match', [
                "get", "rating_value"
              ],
              0, this.unrated_colour,//'#7D7B80', // unrated
              1, '#D1504C', // stressed
              2, '#E2B154', // uncomfortable
              3, '#92c83e', //'#1BB7B7', // comfortable
              4, '#00AA4F',//'#98B446', // enjoyable
              this.unrated_colour //'#7D7B80' // default
            ],
            'line-width': lineWidth,
          }
        },
        firstSymbolId
      );

    }
  }
}
