import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["element"];

  toggle(event) {
    event.preventDefault();
    this.elementTargets.forEach( element => element.classList.toggle('hidden'));
  }

  reset(event) {
    this.elementTargets.forEach( element => {
      if(element.dataset.startsHidden) {
        element.classList.add('hidden')
      } else {
        element.classList.remove('hidden')
      }
    });

  }

  hide(event) {
    this.elementTargets.forEach( element => element.classList.remove('hidden'));
  }
}
