import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { url: String };

  initialize() {
    this.intersectionObserver = new IntersectionObserver(
      (entries) => this.processIntersectionEntries(entries),
      {
        rootMargin: "200px",
      }
    );

    this.mutationObserver = new MutationObserver(
      this.processMutationObserver.bind(this)
    );
    this.mutationObserver.observe(this.element, {
      attributes: false,
      childList: true,
      subtree: true,
    });
  }

  connect() {
    const children = this.element.querySelectorAll("div.highfive");

    // load child hi fives with a count > 0
    children.forEach((hifive) => this.addElementToObserver(hifive));
  }

  disconnect() {
    if (this.intersectionObserver) {
      this.intersectionObserver.disconnect();
    }

    if (this.mutationObserver) {
      this.mutationObserver.disconnect();
    }
  }

  processIntersectionEntries(entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        this.intersectionObserver.unobserve(entry.target);
        this.loadData(entry);
      }
    });
  }

  processMutationObserver(mutationsList) {
    for (const mutation of mutationsList) {
      if (mutation.type == "childList" && mutation.addedNodes.length > 0) {
        mutation.addedNodes.forEach((element) => {
          if (element.nodeType === 1) {
            const children = element.querySelectorAll("div.highfive");

            // load child hi fives with a count > 0
            children.forEach((element) => this.addElementToObserver(element));
          }
        });
      }
    }
  }

  addElementToObserver(element) {
    const countElement = element.getElementsByClassName("highfive-count")[0];

    if (Number(countElement?.getAttribute("data-count")) > 0) {
      this.intersectionObserver.observe(element);
    }
  }

  loadData(entry) {
    const element = entry.target;
    const url = new URL(this.urlValue, document.location.origin);
    url.searchParams.append("id", element.getAttribute("data-hi-fiveable-id"));
    url.searchParams.append(
      "type",
      element.getAttribute("data-hi-fiveable-type")
    );

    fetch(url)
      .then((response) => response.text())
      .then((html) => {
        const div = document.createElement("div");
        div.innerHTML = html.trim();

        element.append(div.firstChild);
      });
  }
}
