import { Controller } from "@hotwired/stimulus";

export default class CarouselController extends Controller {
  static values = { interval: Number };

  connect() {
    this.slides = this.element.querySelectorAll("#feature-slide-carousel");
    this.currentSlide = 0;

    this.nav = document.createElement("div");
    this.nav.classList.add("mobile-app-carousel__navigation");
    this.element.appendChild(this.nav);

    // Create navigation buttons
    this.navCircles = Array.from({ length: this.slides.length }, (_, i) => {
      const circle = document.createElement("button");
      circle.classList.add("mobile-app-carousel__nav-circle");
      this.nav.appendChild(circle);
      circle.addEventListener("click", () => {
        this.changeSlide(i);
        this.startSlideShow();
      });
      return circle;
    });

    this.initializeSlides();
    this.changeSlide(this.currentSlide);
    this.startSlideShow();
  }

  disconnect() {
    this.stopSlideShow();
  }

  initializeSlides() {
    this.slides.forEach((slide) => (slide.style.display = "none"));
  }

  changeSlide(n) {
    this.slides.forEach((slide, index) => {
      slide.style.display = index === n ? "flex" : "none";
      this.navCircles[index].classList.toggle("active", index === n);
    });
    this.currentSlide = n;
  }

  startSlideShow() {
    this.stopSlideShow();
    const intervalTime = this.intervalValue || 10000;
    this.slideInterval = setInterval(() => {
      let nextSlide = (this.currentSlide + 1) % this.slides.length;
      this.changeSlide(nextSlide);
    }, intervalTime);
  }

  stopSlideShow() {
    if (this.slideInterval) {
      clearInterval(this.slideInterval);
    }
  }
}
