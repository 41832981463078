import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { currUrl: String, prevUrl: String, nextUrl: String, container: String, permalink: String }

  connect() {
    this.updateAddressBar()
  }

  prev(event) {
    this.replaceModalContent(this.prevUrlValue)
  }

  next(event) {
    this.replaceModalContent(this.nextUrlValue)
  }

  replaceModalContent(url) {
    let controller = this
    fetch(url)
      .then((r) => r.text())
      .then((html) => {
        $(controller.containerValue + ' > .modal-dialog').replaceWith(html)
      });
  }

  updateAddressBar() {
    // let url = new URL($(this.containerValue + ' > .modal-dialog .gallery-photo-link a').attr('href'))
    let url = new URL(this.permalinkValue)
    window.history.pushState({}, '', url);
  }

  reportCurrentPhoto(e) {
    e.preventDefault()

    $.ajax(this.currUrlValue,
      {
        type: 'PUT',
        dataType: 'json',
        data: {
          reported: true
        },
        error: function(xhr, error) {
          alert('There was an error while reporting this photo')
        },
        success: function(data) {
          window.location.reload()
        }
      }
    )
  }

  unreportCurrentPhoto(e) {
    e.preventDefault()

    $.ajax(this.currUrlValue,
      {
        type: 'PUT',
        dataType: 'json',
        data: {
          reported: false
        },
        error: function(xhr, error) {
          alert('There was an error while reporting this photo')
        },
        success: function(data) {
          window.location.reload()
        }
      }
    )
  }

  deleteCurrentPhoto(e) {
    e.preventDefault()

    $.ajax(this.currUrlValue,
      {
        type: 'DELETE',
        dataType: 'json',
        error: function(xhr, error) {
          alert('There was an error while deleting this photo')
        },
        success: function(data) {
          window.location.reload()
        }
      }
    )
  }

}