import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['map', 'coordsField', 'updateButton'];
  static values = { original: String }
  connect() {
    const map = new google.maps.Map(
      this.mapTarget,
      {
        zoom: 10,
        center: this.center(),
        mapTypeControl: false,
        streetViewControl: false
      }
    );

    this.map = map;

    const marker = new google.maps.Marker({
      position: this.center(),
      map,
      title: "Click to zoom",
    });

    // Live update
    this.map.addListener("center_changed", () => {
      this.coordsFieldTarget.value = `${this.map.center.lat()},${this.map.center.lng()}`
    });
  }

  center() {
    const coords = this.coordsFieldTarget.value.split(',').map(x => parseFloat(x));
    const latLng = {lat: coords[0], lng: coords[1]}
    return latLng;
  }

  reset(e) {
    e.preventDefault()
    this.coordsFieldTarget.value = this.originalValue;
    this.map.setCenter(this.center())
  }

  updateLocationFromMap(e) {
    e.preventDefault()
    this.coordsFieldTarget.value = `${this.map.center.lat()},${this.map.center.lng()}`

  }

}
