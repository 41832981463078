import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "countryCode",
    "addressSearch",
    "address",
    "address2",
    "address3",
    "postcode",
    "coords",
  ];

  connect() {
    const options = {
      componentRestrictions: {},
    };
    if (this.hasCountryCodeTarget && this.countryCodeTarget.value) {
      options.componentRestrictions.country = this.countryCodeTarget.value;
    }
    this.autocomplete = new google.maps.places.Autocomplete(
      this.addressSearchTarget,
      options
    );

    var _this = this;
    this.autocompleteListener = google.maps.event.addListener(
      this.autocomplete,
      "place_changed",
      function () {
        $.publish("address-lookup:change", _this);
      }
    );

    $.subscribe("address-lookup:change", this.placeChanged);
  }

  placeChanged(controller) {
    const place = controller.autocomplete.getPlace();
    let address1 = "";
    let postcode = "";

    if (controller.hasCoordsTarget) {
      controller.coordsTarget.value = JSON.stringify([
        place.geometry.location.lng(),
        place.geometry.location.lat(),
      ]);
    }

    // Get each component of the address from the place details,
    // and then fill-in the corresponding field on the form.
    // place.address_components are google.maps.GeocoderAddressComponent objects
    // which are documented at http://goo.gle/3l5i5Mr

    for (const component of place.address_components) {
      // @ts-ignore remove once typings fixed
      const componentType = component.types[0];

      switch (componentType) {
        case "street_number": {
          address1 = `${component.long_name} ${address1}`;
          break;
        }

        case "route": {
          address1 += component.long_name;
          break;
        }

        case "postal_code": {
          postcode = `${component.long_name}${postcode}`;
          break;
        }

        case "postal_code_suffix": {
          postcode = `${postcode}-${component.long_name}`;
          break;
        }

        case "postal_town": {
          if (controller.hasAddress3Target) {
            controller.address3Target.value = component.long_name;
          }

          break;
        }

        case "locality":
        case "sublocality_level_1":
          if (controller.hasAddress2Target) {
            controller.address2Target.value = component.long_name;
          }
          break;
        case "administrative_area_level_1": {
          if (controller.hasAddress3Target) {
            controller.address3Target.value ||= component.short_name;
          }
          break;
        }
        // case "country":
        // document.querySelector("#country").value = component.long_name;
        // break;
        // }
      }
      if (controller.hasAddressTarget) {
        controller.addressTarget.value = address1;
      }

      if (controller.hasPostcodeTarget) {
        controller.postcodeTarget.value = postcode;
      }
    }
  }
}
