import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['emailForm', 'thanks', 'limit' ]

  connect() {
  }

  facebook(event) {
    event.preventDefault()
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${this.data.get('url')}`,
                'targetWindow',
                'toolbar=no, location=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=500, height=400')
  }

  twitter(event) {
    event.preventDefault()
    let element = event.target.closest('a');

    window.open(`http://twitter.com/intent/tweet?lang=${element.dataset.lang}&url=${this.data.get('url')}&text=${this.data.get('text')}`,
        'targetWindow',
        'toolbar=no, location=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=500, height=300');
  }

  email(event) {
    event.preventDefault()
    var subject = this.data.get('subject')
    var body = this.data.get('text')
    var mailtoUrl = "mailto:?subject=" + encodeURIComponent(subject) + "&body=" + encodeURIComponent(body)
    location.href = encodeURI(mailtoUrl)
  }

}