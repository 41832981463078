import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["openModal"];

  connect() {
    setTimeout(() => {
      this.openModalTarget.classList.add("visible");
    }, 1000);
  }

  close() {
    this.openModalTarget.classList.remove("visible");
  }
}
