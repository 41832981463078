import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.setupDateFields();

    if (window.location.href.includes("/new")) {
      const startDate = new Date();
      this.setStartDate(startDate);
      this.setEndDate(startDate);
      this.disablePastDates();
      this.addEventListeners();
    }

  }

  addMonthToDate(date) {
    const newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() + 1);
    if (newDate.getDate() < date.getDate()) {
      newDate.setDate(0);
    }
    return newDate;
  }

  setStartDate(startDate) {
    const endDate = this.addMonthToDate(startDate);
    document.getElementById("targeted_announcement_start_date_1i").value =
      endDate.getFullYear();
    document.getElementById("targeted_announcement_start_date_2i").value =
      endDate.getMonth();
    document.getElementById("targeted_announcement_start_date_3i").value =
      endDate.getDate();
  }

  setEndDate(startDate) {
    const endDate = this.addMonthToDate(startDate);
    document.getElementById("targeted_announcement_end_date_1i").value =
      endDate.getFullYear();
    document.getElementById("targeted_announcement_end_date_2i").value =
      endDate.getMonth() + 1;
    document.getElementById("targeted_announcement_end_date_3i").value =
      endDate.getDate();
  }

  setupDateFields() {
    this.yearSelect = document.getElementById(
      "targeted_announcement_end_date_1i"
    );
    this.monthSelect = document.getElementById(
      "targeted_announcement_end_date_2i"
    );
    this.daySelect = document.getElementById(
      "targeted_announcement_end_date_3i"
    );
  }

  addEventListeners() {
    this.yearSelect.addEventListener("change", () => this.disablePastDates());
    this.monthSelect.addEventListener("change", () => this.disablePastDates());
  }

  disablePastDates() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;
    const currentDay = currentDate.getDate();

    const selectedYear = parseInt(this.yearSelect.value);
    const selectedMonth = parseInt(this.monthSelect.value);

    // Disable past days, months, and years
    Array.from(this.daySelect.options).forEach((option) => {
      const day = parseInt(option.value);
      option.disabled =
        selectedYear < currentYear ||
        (selectedYear === currentYear && selectedMonth < currentMonth) ||
        (selectedYear === currentYear &&
          selectedMonth === currentMonth &&
          day < currentDay);
    });

    Array.from(this.yearSelect.options).forEach((option) => {
      option.disabled = parseInt(option.value) < currentYear;
    });
  }
}
