import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    url: String,
    target: String,
    features: String,
    startOpen: String
  }

  connect() {
    let params = (new URL(document.location)).searchParams;
    let name = params.get(this.startOpenValue)
    if (name != null) {
      this.show(null);
    }

    return true
  }

  show(event) {
    if (event != null) {
      event.preventDefault()
    }
    // Use dataset, as Stimulus values must (apparently) be on the element specified by the controller
    if (this.urlValue) {
      open(this.urlValue, this.targetValue || "_blank", this.featuresValue || "popup")
    } else {
      console.log("No url specified")
    }

  }

  hide(event) {
    this.containerTarget.modal('hide')
  }

  replaceModalContent(url) {
    let controller = this
    fetch(url)
      .then((r) => r.text())
      .then((html) => {
        let el = $(controller.containerTarget)
        el.html(html)
        el.modal('show')
        el.on('hidden.bs.modal', function(e){
          $(this).empty()
        })

      });

  }


}