import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.nameInput = this.element.querySelector(".department-name-input");
    this.staffCountInput = this.element.querySelector(
      ".department-staff-count-input"
    );
    this.createButton = this.element.querySelector(".create-department-btn");

    if (this.nameInput && this.staffCountInput && this.createButton) {
      this.createButton.disabled = true;
      this.nameInput.addEventListener("input", () => this.updateButtonState());
      this.staffCountInput.addEventListener("input", (event) =>
        this.validateStaffCount(event)
      );
    }
  }

  updateButtonState() {
    const isNameFilled = this.nameInput.value.trim() !== "";
    const isStaffCountValid =
      this.staffCountInput.value.trim() !== "" &&
      parseInt(this.staffCountInput.value, 10) > 0;
    this.createButton.disabled = !(isNameFilled && isStaffCountValid);
  }

  validateStaffCount(event) {
    if (parseInt(event.target.value, 10) === 0) {
      event.target.value = "";
    }
    this.updateButtonState();
  }
}
