import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    bannerHeight: Number,
    menuHeight: Number,
  };
  static targets = ["menu"];

  handleScroll(controller) {
    if (window.scrollY > controller.bannerHeightValue) {
      controller.element.classList.add("navbar-fixed-top");
      document.body.style.paddingTop = controller.menuHeightValue + "px";
    } else {
      controller.element.classList.remove("navbar-fixed-top");
      document.body.style.paddingTop = "0";
    }
  }
  connect() {
    const banner = document.querySelector(".view-spy-header-container");
    if (banner) {
      this.bannerHeightValue = document.querySelector(
        ".view-spy-header-container"
      ).offsetHeight;
      this.menuHeightValue = this.menuTarget.offsetHeight;
      window.addEventListener("scroll", () => this.handleScroll(this));
    }
  }

  disconnect() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  load() {
    this.menuHeightValue = this.menuTarget.offsetHeight;
  }

  resize() {
    const banner = document.querySelector(".view-spy-header-container");

    if (banner) {
      this.bannerHeightValue = document.querySelector(
        ".view-spy-header-container"
      ).offsetHeight;
    }
  }
}
