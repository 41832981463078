import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["source", "success", "failure"];
  static values = { copyme: String, successHideAfter: Number, failureText: String };

  connect() {}

  copyContent(event) {
    const text = this.copymeValue || this.sourceTarget.value;
    const type = "text/plain";
    const blob = new Blob([text], { type });
    const data = [new ClipboardItem({ [type]: blob })];

    var _this = this;
    if (navigator.clipboard === undefined) {
      _this.failureTarget.classList.remove("hidden");
      _this.successTarget.classList.add("hidden");
      window.setTimeout(function () {
        if (_this.hasFailureTextValue) {
          let elem = _this.failureTarget.querySelector(_this.failureTextValue);
          if (elem) {
            elem.focus();
            elem.select();
          }
        }
      }, 500);
      console.log("navigator.clipboard not supported.");
    } else {
      navigator.clipboard.write(data).then(
        function () {
          /* success */
          _this.successTarget.classList.remove("hidden");
          _this.failureTarget.classList.add("hidden");

          if (_this.hasSuccessHideAfterValue) {
            window.setTimeout(function () {
              // Stolen from tabs_controller.js clearActiveTabs()
              // This only works because the clipboard and tabs controllers are on the same element
              _this.element.querySelectorAll(".active").forEach(function (value, index, list) {
                value.classList.remove("active");
              });
            }, _this.successHideAfterValue * 1000);
          }
          console.log("Copied to clipboard.");
        },
        function () {
          /* failure */
          _this.successTarget.classList.add("hidden");
          _this.failureTarget.classList.remove("hidden");
          console.log("Failed to copy to clipboard.");
        }
      );
    }
  }
}
