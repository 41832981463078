import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.nextButton = document.getElementById("js-save-next-ta");
    this.titleInput = document.getElementById("targeted_announcement_title");
    this.targetUrlInput = document.getElementById(
      "targeted_announcement_link_url"
    );
    this.linkText = document.getElementById("targeted_announcement_link_text");
    this.textInput = document.getElementById("redactor-uuid-0");
    this.platformSelect = document.getElementById(
      "targeted_announcement_platform"
    );

    this.updateButtonState();
    this.addEventListeners();
  }

  addEventListeners() {
    this.titleInput.addEventListener("input", () => this.updateButtonState());
    this.targetUrlInput.addEventListener("input", () =>
      this.updateButtonState()
    );
    this.linkText.addEventListener("input", () => this.updateButtonState());
    this.textInput.addEventListener("input", () => this.updateButtonState());

    this.platformSelect.addEventListener("change", () =>
      this.updateButtonState()
    );
  }

  updateButtonState() {
    const titleIsValid = this.titleInput.value.trim().length > 0;
    const urlIsValid = this.targetUrlInput.value.trim().length > 0;
    const linkTextValid = this.linkText.value.trim().length > 0;
    const strippedContent = this.textInput.innerHTML
      .replace(/<[^>]*>/g, "")
      .replace(/&nbsp;|&zwnj;|&zwj;|&lrm;|&rlm;/gi, " ")
      .trim();
    const hasVisibleContent = /[^\s\u200B]/.test(strippedContent);
    const contentIsValid = hasVisibleContent;
    const platformIsValid = this.platformSelect.value.trim().length > 0;

    if (this.nextButton) {
      if (
        titleIsValid &&
        urlIsValid &&
        contentIsValid &&
        linkTextValid &&
        platformIsValid
      ) {
        this.nextButton.classList.remove("disabled");
        this.nextButton.disabled = false;
      } else {
        this.nextButton.classList.add("disabled");
        this.nextButton.disabled = true;
      }
    }
  }
}
