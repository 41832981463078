import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["submit"];

  connect() {
    this.nameInput = this.element.querySelector(
      "#department_profile_department_staff_count"
    );

    this.submitButton = this.element.querySelector(
      'input[type="submit"], button[type="submit"]'
    );

    if (this.nameInput) {
      this.nameInput.addEventListener("input", () => this.updateButtonState());
    }

    this.updateButtonState();
  }

  updateButtonState() {
    const staffCount = this.nameInput.value.trim();
    if (staffCount === "0" || staffCount === "") {
      this.nameInput.value = "";
      this.submitButton.disabled = true;
    } else {
      this.submitButton.disabled = false;
    }
  }
}
