import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "mapSettings",
    "baseMap",
    "exports",
    "mapLineSettings",
    "pill",
    "mapSettingsTab1",
    "mapSettingsTab2",
    "mapSettingsTab3",
    "fullscreenIcon",
    "minScreenIcon",
  ];

  static values = {
    superAdmin: { type: Boolean, default: null },
  };

  connect() {
    if (this.superAdminValue) {
      this.mapSettingsTab1Target.classList.add("map-nav-active");
    } else if (this.hasMapSettingsTab1Target) {
      this.mapSettingsTab1Target.classList.add("map-nav-active");
    }

    this.keydownEventListener = (event) => {
      if (event.key === "Escape") {
        this.closeSettings();
        this.smallScreen();
        this.closeLineSettings();
        this.closeBaseMap();
        this.closeExports();
      }
    };

    document.addEventListener("keydown", this.keydownEventListener);
    this.mapContainer = document.getElementById("map-container");

    this.handleDivClicks();
  }

  disconnect() {
    document.removeEventListener("keydown", this.keydownEventListener);
  }

  handleDivClicks() {
    const cmSelect = this.element.querySelectorAll(".comfort-map__select-div");

    cmSelect.forEach((div) => {
      div.addEventListener("click", () => {
        const selectedValue = div.getAttribute("data-value");
        const lineScaleInput = document.getElementById("lineScaleInput");
        cmSelect.forEach((d) => d.classList.remove("active"));
        div.classList.add("active");
        if (lineScaleInput) {
          lineScaleInput.value = selectedValue;
          const event = new Event("change");
          lineScaleInput.dispatchEvent(event);
        }
      });
    });
  }

  toggleVisibility(target) {
    if (target.style.display === "block") {
      target.style.display = "none";
    } else {
      target.style.display = "block";
    }
  }

  hideAllExcept(targetToShow) {
    const targets = [
      this.hasExportsTarget ? this.exportsTarget : null,
      this.hasMapLineSettingsTarget ? this.mapLineSettingsTarget : null,
      this.hasMapSettingsTarget ? this.mapSettingsTarget : null,
      this.hasBaseMapTarget ? this.baseMapTarget : null,
    ];

    targets.forEach((target) => {
      if (target && target !== targetToShow) {
        target.style.display = "none";
      }
    });
  }

  openExports() {
    this.hideAllExcept(this.exportsTarget);
    this.toggleVisibility(this.exportsTarget);
  }

  openSettings() {
    this.hideAllExcept(this.mapSettingsTarget);
    this.toggleVisibility(this.mapSettingsTarget);
  }

  openLineSettings() {
    this.hideAllExcept(this.mapLineSettingsTarget);
    this.toggleVisibility(this.mapLineSettingsTarget);
  }

  openBaseMap() {
    this.hideAllExcept(this.baseMapTarget);
    this.toggleVisibility(this.baseMapTarget);
  }

  closeExports() {
    this.exportsTarget.style.display = "none";
  }

  closeSettings() {
    this.mapSettingsTarget.style.display = "none";
  }

  closeLineSettings() {
    this.mapLineSettingsTarget.style.display = "none";
  }

  closeBaseMap() {
    this.baseMapTarget.style.display = "none";
  }

  fullScreen() {
    this.mapContainer.classList.add("map-container__insert__full-screen");
    this.fullscreenIconTarget.style.display = "none";
    this.minScreenIconTarget.style.display = "block";
    this.pillTarget.classList.add("map-settings__settings-pill--full-screen");
  }

  smallScreen() {
    this.mapContainer.classList.remove("map-container__insert__full-screen");
    this.fullscreenIconTarget.style.display = "block";
    this.minScreenIconTarget.style.display = "none";
    this.pillTarget.classList.remove(
      "map-settings__settings-pill--full-screen"
    );
  }

  tab1(event) {
    if (this.hasMapSettingsTab1Target) {
      this.mapSettingsTab1Target.classList.add("map-nav-active");
    }
    if (this.hasMapSettingsTab2Target) {
      this.mapSettingsTab2Target.classList.remove("map-nav-active");
    }
    if (this.hasMapSettingsTab3Target) {
      this.mapSettingsTab3Target.classList.remove("map-nav-active");
    }
    this.updateActiveTab(event);
  }

  tab2(event) {
    if (this.hasMapSettingsTab1Target) {
      this.mapSettingsTab1Target.classList.remove("map-nav-active");
    }
    if (this.hasMapSettingsTab2Target) {
      this.mapSettingsTab2Target.classList.add("map-nav-active");
    }
    if (this.hasMapSettingsTab3Target) {
      this.mapSettingsTab3Target.classList.remove("map-nav-active");
    }
    this.updateActiveTab(event);
  }

  tab3(event) {
    if (this.hasMapSettingsTab1Target) {
      this.mapSettingsTab1Target.classList.remove("map-nav-active");
    }
    if (this.hasMapSettingsTab2Target) {
      this.mapSettingsTab2Target.classList.remove("map-nav-active");
    }
    if (this.hasMapSettingsTab3Target) {
      this.mapSettingsTab3Target.classList.add("map-nav-active");
    }
    this.updateActiveTab(event);
  }

  updateActiveTab(event) {
    this.element.querySelectorAll(".map-key__nav li").forEach((li) => {
      li.classList.remove("map-key__nav--active");
    });

    event.currentTarget.classList.add("map-key__nav--active");
  }
}
