import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.dropdown = document.getElementById("department-dropdown");
    this.joinButton = document.querySelector(".join-button");

    if (this.dropdown && this.joinButton) {
      this.toggleJoinButton();
      this.dropdown.addEventListener("change", () => this.toggleJoinButton());
    }
  }

  toggleJoinButton() {
    if (this.dropdown.value) {
      this.joinButton.disabled = false;
    } else {
      this.joinButton.disabled = true;
    }
  }
}
