import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { userId: Number, eventId: Number };

  connect() {
    const localStorageKey = `user_event_promotion_share_modal_count${this.userIdValue}_${this.eventIdValue}`;
    const urlParams = new URLSearchParams(window.location.search);
    const forceModal = urlParams.get("force_modal") === "1";

    if (forceModal) {
      localStorage.setItem(localStorageKey, "false");
      this.element.style.display = "block";
    } else if (localStorage.getItem(localStorageKey) === "true") {
      this.element.style.display = "none";
    } else {
      this.element.style.display = "block";
    }
  }

  closeModal(event) {
    event.preventDefault();
    const localStorageKey = `user_event_promotion_share_modal_count${this.userIdValue}_${this.eventIdValue}`;
    this.element.style.display = "none";
    localStorage.setItem(localStorageKey, "true");

    // Remove force_modal from URL
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("force_modal")) {
      urlParams.delete("force_modal");
      const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
      window.history.pushState({}, "", newUrl);
    }
  }
}
