import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.chosenContainer = document.getElementById(
      "announcement_site_ids_chosen"
    );
    this.createButton = document.getElementById("create_announcement_button");
    this.titleInput = document.getElementById("announcement_title");
    this.targetUrlInput = document.getElementById("announcement_link_url");
    this.linkText = document.getElementById("announcement_link_text");
    this.textInput = document.getElementById("redactor-uuid-0");
    this.imageInput = document.getElementById("announcement_text_image");
    this.imageAltTextInput = document.getElementById(
      "announcement_image_alt_text"
    );
    this.imageAltTextLabel = document.querySelector(
      ".announcement-row__half--alt-text .stat-label"
    );

    this.setupEventListeners();
    this.updateAltTextLabel();
    this.updateButtonState();
  }

  setupEventListeners() {
    this.imageInput.addEventListener("change", () => {
      this.updateAltTextLabel();
      this.updateButtonState();
    });

    this.titleInput.addEventListener("input", () => this.updateButtonState());
    this.targetUrlInput.addEventListener("input", () =>
      this.updateButtonState()
    );
    this.linkText.addEventListener("input", () => this.updateButtonState());
    this.textInput.addEventListener("input", () => this.updateButtonState());
    this.imageAltTextInput.addEventListener("input", () =>
      this.updateButtonState()
    );

    const observer = new MutationObserver(() => this.updateButtonState());
    observer.observe(this.chosenContainer, { childList: true, subtree: true });
  }

  imageIsUploaded() {
    return this.imageInput.files && this.imageInput.files[0];
  }

  altTextIsValid() {
    return (
      !this.imageIsUploaded() || this.imageAltTextInput.value.trim().length > 0
    );
  }

  updateAltTextLabel() {
    if (this.imageIsUploaded()) {
      this.imageAltTextLabel.innerHTML = "Image alt text *";
    } else {
      this.imageAltTextLabel.innerHTML = "Image alt text";
    }
  }

  updateButtonState() {
    const hasSelection =
      this.chosenContainer.getElementsByClassName("search-choice").length > 0;
    const titleIsValid = this.titleInput.value.trim().length > 0;
    const urlIsValid = this.targetUrlInput.value.trim().length > 0;
    const linkTextValid = this.linkText.value.trim().length > 0;
    const contentIsValid = this.textInput.textContent.trim().length > 0;

    if (this.createButton) {
      if (
        hasSelection &&
        titleIsValid &&
        urlIsValid &&
        contentIsValid &&
        linkTextValid &&
        this.altTextIsValid()
      ) {
        this.createButton.classList.remove("disabled");
        this.createButton.disabled = false;
      } else {
        this.createButton.classList.add("disabled");
        this.createButton.disabled = true;
      }
    }
  }
}
