import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["file"];

  connect() {
    if (this.hasFileTarget) {
      this.fileTarget.addEventListener(
        "change",
        this.validateFileSize.bind(this)
      );
    }
  }

  disconnect() {
    if (this.hasFileTarget) {
      this.fileTarget.removeEventListener(
        "change",
        this.validateFileSize.bind(this)
      );
    }
  }

  validateFileSize(event) {
    const fileInput = event.target;

    console.log("fileInput", fileInput);
    if (fileInput.files.length > 0) {
      const fileSize = fileInput.files[0].size / 1024; // Size in KB
      const maxFileSize = 150; // Maximum file size in KB

      if (fileSize > maxFileSize) {
        alert(
          `Your file size exceeds 150 KB. Please use a compression tool to reduce the size. For a tool that works well you can copy and paste this URL into your browser: https://squoosh.app`
        );

        fileInput.value = "";
      }
    }
  }
}
