import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["statusBar"];
  static values = {
    maxBounds: { type: Array, default: null },
    centrePoint: { type: Array, default: null },
    siteSize: { type: Number, default: null },
    superAdmin: { type: Boolean, default: null },
    userToken: { type: String, default: null },
    siteId: { type: Number, default: null },
    mapLoaded: Boolean,
  };

  connect() {
    this.unrated_colour = "#7D7B80";
    this.greyColour = "#7D7B80";
    this.clearColour = "rgba(255,0,255,0)";
    this.line_width = 5;
    this.defaultZoom = 5;

    this.setBaseUrl();
    let params = new URLSearchParams(document.location.search);
    this.mapType = params.get("mapType") || "avg";
    this.mapLoaded = false;

    mapboxgl.accessToken =
      "pk.eyJ1IjoiY29ycmVjdGVkdGltZSIsImEiOiJZQWpHMy1JIn0.wtBkQp-NkyRbRdcot5o-9g";
    this.map = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/mapbox/dark-v11",
      center: this.center(),
      bounds: this.bounds(),
      maxBounds: this.maxBounds(),
      maxZoom: 24,
      // minZoom: 12,
      // zoom: 14, // starting zoom
      transformRequest: (url, resourceType) => {
        if (resourceType === "Tile" && url.indexOf("http://localhost") > -1) {
          return {
            url: url.replace("http", "http"),
            headers: { Authorization: "Bearer 1234567890" },
          };
        }
      },
    });

    this.map.on("load", () => {
      this.mapLoadedValue = true;
      this.hideLoader();
      let params = new URLSearchParams(document.location.search);
      this.initComfortMap(params);
      this.drawBounds();
      this.setInitialLineThickness(3);
      // for transport planners with 1 site
      if (this.siteSizeValue === 1 && !this.superAdminValue) {
        this.map.setZoom(14);
        // for transport planners with 2 or more sites
      } else if (this.siteSizeValue > 1 && !this.superAdminValue) {
        this.map.setZoom(8);
        // for super admins
      } else {
        this.map.setZoom(14);
      }
    });

    this.map.on("idle", () => {
      if (!this.mapLoadedValue) {
        this.showLoader();
      } else {
        this.hideLoader();
      }
    });

    this.map.on("sourcedata", (event) => {
      if (!event.isSourceLoaded) {
        this.showLoader();
      }
    });

    document.getElementById("js-full-screen").addEventListener("click", () => {
      setTimeout(() => {
        this.map.resize();
      }, 50);
    });
  }

  showLoader() {
    const mapContainer = document.getElementById("map-overlay-loader");
    const mapContainerMapbox = document.querySelector(".mapboxgl-map");
    if (mapContainer) {
      mapContainer.classList.add("map-container__insert--loading");
      mapContainerMapbox.style.opacity = "0.8";
      mapContainer.style.zIndex = "1";
    }
    const loader = document.getElementById("comfortmap__loading-container");
    if (loader) {
      loader.style.display = "block";
    }
  }

  hideLoader() {
    const mapContainer = document.getElementById("map-overlay-loader");
    const mapContainerMapbox = document.querySelector(".mapboxgl-map");
    if (mapContainer) {
      mapContainer.classList.remove("map-container__insert--loading");
      mapContainerMapbox.style.opacity = "1";
      mapContainer.style.zIndex = "-1";
    }
    const loader = document.getElementById("comfortmap__loading-container");
    if (loader) {
      loader.style.display = "none";
    }
  }

  setInitialLineThickness(thickness) {
    // if (this.map.getLayer("comfort_avg_layer")) {
    //   this.map.setPaintProperty("comfort_avg_layer", "line-width", thickness);
    // }
  }

  resizeMap() {
    if (this.map) {
      this.map.resize();
    }
  }

  bounds() {
    if (this.hasMaxBoundsValue && this.maxBoundsValue.length > 0) {
      return mapboxgl.LngLatBounds.convert(this.maxBoundsValue);
    } else {
      return undefined;
    }
  }

  maxBounds() {
    if (this.hasMaxBoundsValue && this.maxBoundsValue.length > 0) {
      return mapboxgl.LngLatBounds.convert(this.maxBoundsValue);
    } else {
      return undefined;
    }
  }

  center() {
    if (
      this.hasCentrePointValue &&
      this.centrePointValue.length > 0 &&
      this.centrePointValue[0] != 0 &&
      this.centrePointValue[1] != 0
    ) {
      return new mapboxgl.LngLat(
        this.centrePointValue[0],
        this.centrePointValue[1]
      );
    } else {
      return undefined;
    }
  }

  updateTileParams(tile_params) {
    if (!this.map || typeof this.map.getSource !== "function") {
      console.error("Map is not initialized.");
      return;
    }

    // Ensure security requirements can be met
    tile_params =
      tile_params +
      "&token=" +
      this.userTokenValue +
      "&site_id=" +
      this.siteIdValue;

    let firstSymbolId = this.getFirstSymbolId();
    // this.map.removeSource("comfort_avg")
    // let features = this.map.queryRenderedFeatures({layers: ['comfort_avg']})
    this.map.removeLayer("comfort_avg_layer");
    this.insertLayer(
      "comfort_avg",
      tile_params,
      firstSymbolId,
      this.line_width
    );
  }

  setLocation({
    detail: { lat, lon, tile_params, line_thickness, hide_unrated, hide_status_bar },
  }) {
    if (lat && lon) {
      // console.log(`setLocation received: ${lat}, ${lon}`);
      if (this.siteSizeValue > 1 && !this.superAdminValue) {
        this.map.setCenter([lon, lat]);
        this.map.setZoom(14);
      } else {
        this.map.flyTo({ center: [lon, lat] });
      }
    } else if (line_thickness) {
      this.line_width = parseInt(line_thickness);
      this.map.setPaintProperty(
        "comfort_avg_layer",
        "line-width",
        this.line_width
      );
    } else if (hide_unrated != undefined) {
      if (hide_unrated) {
        this.unrated_colour = this.clearColour;
      } else {
        this.unrated_colour = this.greyColour;
      }

      this.map.setPaintProperty(
        "comfort_avg_layer",
        "line-color",
        this.lineColours()
      );
    } else if (hide_status_bar != undefined) {
      if (hide_status_bar) {
        this.statusBarTarget.classList.add("hidden");
      } else {
        this.statusBarTarget.classList.remove("hidden");
      }
    } else {
      this.updateTileParams(tile_params);
    }
  }

  setBaseUrl() {
    if (
      document.getElementsByTagName("main")[0].classList.contains("development")
    ) {
      this.baseUrl = "http://localhost:3002";
    } else {
      this.baseUrl = "https://journey.lovetoride.net";
    }
  }

  initComfortMap(params) {
    let lat = "";
    let lon = "";
    // if tp2 or tp1
    if (this.siteSizeValue > 0 && !this.superAdminValue) {
      lat = params.get("lat");
      lon = params.get("lon");
    } else {
      // set to ltr bristol as default for super admins
      lat = params.get("lat") || 51.444229;
      lon = params.get("lon") || -2.563627;
    }

    if (lat != null && lon != null) {
      this.map.setCenter([parseFloat(lon), parseFloat(lat)]);
      this.map.setZoom(params.get("zoom") || this.defaultZoom);
    }

    let firstSymbolId = this.getFirstSymbolId();

    var max_age = params.get("max_age");
    if (max_age == null) {
      max_age = 3600;
    }
    var tile_params =
      "?token=" +
      this.userTokenValue +
      "&site_id=" +
      this.siteIdValue +
      "&max_age=" +
      max_age +
      "&cache_bust=" +
      params.get("cache_bust") +
      "&precision=1";

    let debug_options = params.get("debug_trip") || "";
    if (debug_options.includes("hu")) {
      this.unrated_colour = this.clearColour;
    }

    const lineWidth = params.get("line_width") || "5";
    this.insertLayer(
      "comfort_avg",
      tile_params,
      firstSymbolId,
      parseInt(lineWidth)
    );

    this.map.on("mouseenter", "comfort_avg_layer", (e) => {
      this.updateStatusBar(e.lngLat, e.features[0].properties);
    });

    this.map.on("mouseenter", "features_layer", (e) => {
      this.updateStatusBar(e.lngLat, e.features[0].properties);
    });

    if (this.maxBounds() != undefined) {
      this.map.fitBounds(this.maxBounds());
      this.map.setMinZoom(0);
    } else if (this.center() != undefined) {
      this.map.jumpTo({ center: this.center() });
      this.map.setZoom(this.defaultZoom);
    }
  }

  drawBounds() {
    let bounds = this.maxBounds();
    if (bounds == undefined) {
      return;
    }

    this.map.addLayer({
      id: "line-bounding-box",
      type: "line",
      paint: {
        "line-color": "#666",
        "line-width": 3,
        "line-opacity": 0.9,
      },
      source: {
        type: "geojson",
        data: {
          type: "Feature",
          properties: {},
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                bounds.getSouthWest().toArray(),
                bounds.getSouthEast().toArray(),
                bounds.getNorthEast().toArray(),
                bounds.getNorthWest().toArray(),
                bounds.getSouthWest().toArray(),
              ],
            ],
          },
        },
      },
    });
  }
  updateStatusBar(lngLat, properties) {
    if (this.hasStatusBarTarget) {
      // console.log(properties);
      var html = `${lngLat} - <a target="_blank" href="https://metabase.lovetoride.net/question/1123-segment-debug?segment_id=${properties.segment_id}">Segment ${properties.segment_id}</a>
         From <a target="_blank" href="https://www.openstreetmap.org/way/${properties.osm_id}">OSM ${properties.osm_id}</a>
         <span>Trips: ${properties.trips_count}</span> 
         <span>Ratings: ${properties.ratings_count}</span>
         <span>Unique raters: ${properties.unique_raters_count}</span>
         <span>Unique riders: ${properties.unique_riders_count}</span>
  `;
      if (properties.rating_value != null) {
        html += `&nbsp;<span class="rating-${properties.rating_value}">Average rating value ${properties.rating_value}</span>`;
      }
      this.statusBarTarget.innerHTML = html;
    }
  }

  getFirstSymbolId() {
    for (const layer of this.map.getStyle().layers) {
      if (layer.type === "symbol") {
        return layer.id;
      }
    }
    return null; // Return null if no symbol layer is found
  }

  lineColours() {
    return [
      "interpolate",
      ["linear"],
      ["to-number", ["get", "trip_count"]],
      0,
      this.unrated_colour,
      1,
      "#D1504C",
      2,
      "#E2B154",
      3,
      "#92c83e",
      4,
      "#00AA4F",
    ];
  }

  insertLayer(layer, tile_params, firstSymbolId, lineWidth) {
    if (layer === "comfort_avg") {
      if (this.map.getSource("comfort_avg")) {
        this.map
          .getSource("comfort_avg")
          .setTiles([
            `${this.baseUrl}/api/tiles/{z}/{x}/{y}.mvt${tile_params}&heatmap=true`,
          ])
          .reload();
      } else {
        this.map.addSource("comfort_avg", {
          type: "vector",
          tiles: [
            `${this.baseUrl}/api/tiles/{z}/{x}/{y}.mvt${tile_params}&heatmap=true`,
          ],
          minzoom: 11,
          maxzoom: 15,
        });
      }

      if (!this.map.getLayer("comfort_avg_layer")) {
        this.map.addLayer(
          {
            id: "comfort_avg_layer",
            type: "heatmap",
            source: "comfort_avg",
            "source-layer": "default",
            layout: {
              "line-cap": "round",
              "line-gap": 10,
              "line-join": "miter",
            },
            paint: {
              // "line-color": this.lineColours(),
              // Adjust the heatmap
              // "line-width": lineWidth,
              // Increase the heatmap weight based on frequency and property magnitude
              "heatmap-weight": {
                property: "trips_count",
                type: "exponential",
                stops: [
                  [0, 0],
                  [1000, 1],
                ],
              },
              // Increase the heatmap color weight weight by zoom level
              // heatmap-intensity is a multiplier on top of heatmap-weight
              "heatmap-intensity": {
                stops: [
                  [12, 0.1],
                  [24, 5],
                ],
              },
              // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
              // Begin color ramp at 0-stop with a 0-transparancy color
              // to create a blur-like effect.
              // assign color values be applied to points depending on their density
              "heatmap-color": [
                "interpolate",
                ["linear"],
                ["heatmap-density"],
                0,
                "rgba(179,0,0, 0)",
                0.2,
                "rgba(227,74,51, 0.5)",
                0.4,
                "rgba(252,141,89, 0.7)",
                0.6,
                "rgba(253,204,138, 0.9)",
                1.0,
                "rgba(254,240,217,0.9)",
              ],
              // increase radius
              // Adjust the heatmap radius by zoom level
              "heatmap-radius": {
                stops: [
                  [12, 10],
                  [24, 20],
                ],
              },
              // Transition from heatmap to circle layer by zoom level
              // 'heatmap-opacity': {
              //   default: 1,
              //   stops: [
              //     [13, 0],
              //     [14, 1]
              //   ]
              // }
            },
          },
          firstSymbolId
        );
      } else {
        // Optionally, update properties if the layer already exists
        this.map.setPaintProperty("comfort_avg_layer", "line-color", "#92c83e");
        this.map.setPaintProperty("comfort_avg_layer", "line-width", lineWidth);
      }
    }
  }
}
