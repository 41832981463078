import { Controller } from "@hotwired/stimulus";

export default class TestimonialsController extends Controller {
  connect() {
    // Assuming '.mobile-app-testimonials' are the sections you want to shuffle.
    this.testimonialsContainer = this.element;
    this.testimonials = this.element.querySelectorAll(
      ".mobile-app-testimonials"
    );

    // Shuffle testimonials
    this.shuffleTestimonials();

    // Set visibility for testimonials
    this.initializeTestimonials();

    // Start cycling testimonials
    this.startTestimonialsCycle();

    // Listen for visibility change to control the cycle
    document.addEventListener("visibilitychange", this.handleVisibilityChange);
  }

  disconnect() {
    this.stopTestimonialsCycle();
    document.removeEventListener(
      "visibilitychange",
      this.handleVisibilityChange
    );
  }

  shuffleTestimonials() {
    for (let i = this.testimonials.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      this.testimonialsContainer.insertBefore(
        this.testimonials[j],
        this.testimonials[i]
      );
    }
  }

  initializeTestimonials() {
    this.testimonials.forEach((testimonial, index) => {
      testimonial.style.opacity = 0;
      testimonial.style.display = "none";
    });

    // Display the first testimonial
    if (this.testimonials.length > 0) {
      this.testimonials[0].style.display = "flex";
      this.testimonials[0].style.opacity = 1;
    }

    // Set the initial index for cycling
    this.currentTestimonial = 0;
  }

  startTestimonialsCycle() {
    this.testimonialsInterval = setInterval(
      () => this.cycleTestimonials(),
      13000
    );
  }

  stopTestimonialsCycle() {
    clearInterval(this.testimonialsInterval);
  }

  cycleTestimonials() {
    this.fadeOut(this.testimonials[this.currentTestimonial], () => {
      this.currentTestimonial =
        (this.currentTestimonial + 1) % this.testimonials.length;
      this.fadeIn(this.testimonials[this.currentTestimonial]);
    });
  }

  fadeOut(element, callback) {
    let op = 1;
    let timer = setInterval(() => {
      if (op <= 0.1) {
        clearInterval(timer);
        element.style.display = "none";
        element.style.opacity = 0;
        if (callback) callback();
      } else {
        element.style.opacity = op;
        op -= op * 0.1;
      }
    }, 20);
  }

  fadeIn(element) {
    let op = 0.1;
    element.style.display = "flex";
    let timer = setInterval(() => {
      if (op >= 1) {
        clearInterval(timer);
        element.style.opacity = 1;
      } else {
        element.style.opacity = op;
        op += op * 0.1;
      }
    }, 20);
  }

  handleVisibilityChange = () => {
    if (document.visibilityState === "visible") {
      this.startTestimonialsCycle();
    } else {
      this.stopTestimonialsCycle();
    }
  };
}
