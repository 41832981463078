import { Controller } from "@hotwired/stimulus";
import { useIntersection } from "stimulus-use";

export default class extends Controller {
  static targets = ["filter"];

  initialize() {
    this.firstLoad = false;
  }

  connect() {
    useIntersection(this);
  }

  appear(entry) {
    // triggered when the element appears within the viewport
    if (!this.firstLoad) {
      this.firstLoad = true;
      this.filter();
    }
  }

  filter() {
    const element = this.filterTarget;
    const filter = element.value;

    $("#influence-stats-data").load(this.data.get("url"), "month=" + filter);
  }
}
