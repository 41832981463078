import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
  connect() {
      this.sortable = Sortable.create(this.element, {
        group: 'shared',
        animation: 150,
        onEnd: this.end.bind(this)
      })
  }

  end(event) {
    if (event.item.dataset.action) {
      this.callTargetUpdate(event)
    }

    if (this.data.get("url")) {

      let id = event.item.dataset.id
      let data = new FormData()
      data.append("position", event.newIndex)


      fetch(this.data.get("url").replace("replace_id", id), {
        method: "PUT",
        body: JSON.stringify({
                position: event.newIndex
              }),
        headers: {
          "Content-Type": "application/json"
        },
        credentials: 'same-origin'
      })
    }
  }

  callTargetUpdate(event) {
    let target = event.item
    let controller = this.application.getControllerForElementAndIdentifier(target, target.dataset.controller)

    if (controller && target.dataset.action) {
      controller[target.dataset.action](event)
    }
  }
}