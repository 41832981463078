import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['tableElement', 'tableBody']

  connect() {
    var element = $(this.tableElementTarget)
    element.on("sortBegin", {controller: this}, this.resortBegin);
    element.on("sortEnd", {controller: this}, this.resort);
  }

  resortBegin(event, table) {
    var controller = event.data.controller;
    var rows = controller.tableBodyTarget.childElementCount

    // Take note of the rows, as the jquery.tablesorter adds cached rows part way through the process
    controller.tableElementTarget.dataset.rows = rows
    controller.tableElementTarget.classList.add('busy')
  }

  resort(event, table) {
    event.preventDefault();

    var controller = event.data.controller;

    let url = controller.constructRequestUrl()

    fetch(url)
      .then(response => {
        status = response.status;
        return response;
      })
      .then(response => response.text())
      .then(html => {
        controller.tableBodyTarget.replaceChildren();
        controller.tableBodyTarget.innerHTML = html;
        controller.tableElementTarget.dataset.rows = controller.tableBodyTarget.childElementCount
        // Rails can return a status 203 (:partial_content) to leave the load more button in place
        // A status of 200 (:ok) means we're done, with no more pages of results to follow
        controller.tableElementTarget.classList.remove('busy')
        if (status == '200') {
          controller.loadButtonTarget.classList.add('hidden');
        }
      });
  }

  // append query params that are not blank
  appendQueryParams(baseURL)  {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    for (const [key, value] of Object.entries(params)) {
      if (value != '' && key != 'offset') { baseURL.searchParams.append(key, value); }
    }

    return baseURL;
  }

  constructRequestUrl() {
    let url = new URL(this.data.get('url'), document.location.origin);

    var header = this.tableElementTarget.querySelectorAll('.tablesorter-headerAsc, .tablesorter-headerDesc')[0]
    var rows = this.tableElementTarget.dataset.rows
    var column = header.dataset.sortName
    var order = header.classList.contains('tablesorter-headerAsc') ? 'asc' : 'desc'
    url.searchParams.append('offset', 0);
    url.searchParams.append('limit', rows);
    url.searchParams.append('column', column);
    url.searchParams.append('order', order);

    if (this.data.get("include-query-params")) {
      url = this.appendQueryParams(url);
    }
    return url
  }

}