// Taken from https://github.com/stimulus-components/stimulus-character-counter
// We're not yet on latest stimulus, so yarn package doesn't play well
// Code also modified to handle Redactor text areas.
// Use 'redactorInput' target for a :big_text type simple_form field, which is decorated and replaced with a Redactor text editor.
// Use 'input' target for a :text type simple_form field.

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "redactorInput", "counter"]
  static values = { countdown: Boolean }

  initialize() {
    this.update = this.update.bind(this)
  }

  connect() {

    this.update()

    if(this.hasRedactorInputTarget) {
      this.mutationObserver = new MutationObserver(this.update)
      this.mutationObserver.observe(this.redactorInputTarget.previousElementSibling,
        {characterData: true, childList: true, subtree: true})
    }
    if(this.hasInputTarget) {
      var controller = this;
      ["compositionupdate", "change", "textInput", "input"].forEach( (e) => {
        controller.inputTarget.addEventListener(e, controller.update)
        if(controller.inputTarget.previousElementSibling) {
          controller.inputTarget.previousElementSibling.addEventListener(e, controller.update)
        }
      })
    }

  }

  disconnect() {
    if(this.hasInputTarget) {
      var controller = this;
      ["compositionupdate", "change", "textInput", "input"].forEach((e) => {
        controller.inputTarget.removeEventListener(e, controller.update)
        controller.inputTarget.previousElementSibling.removeEventListener(e, controller.update)
      })
    }

    if(this.hasRedactorInputTarget) {
      this.mutationObserver.disconnect()
    }
  }

  update() {
    if(this.hasRedactorInputTarget) {
      var controller = this
      window.setTimeout(function(){controller.counterTarget.innerHTML = controller.count.toString()}, 100)
    }
    this.counterTarget.innerHTML = this.count.toString()
  }

  get count() {

    let value = 0
    if(this.hasRedactorInputTarget) {
      // value = this.redactorInputTarget.previousElementSibling.innerText.length
      var dummyElement = document.createElement('div')
      dummyElement.innerHTML = this.redactorInputTarget.value
      value = dummyElement.innerText.length
      // value = this.redactorInputTarget.value.length
    } else if (this.hasInputTarget) {
      value = this.inputTarget.value.length
    }

    if (this.hasCountdownValue) {
      if (this.maxLength < 0) {
        console.error(
          `[stimulus-character-counter] You need to add a maxlength attribute on the input to use countdown mode. The current value is: ${this.maxLength}.`
        )
      }

      value = Math.max(this.maxLength - value, 0)
    }

    return value
  }

  get maxLength() {
    if (this.hasRedactorInputTarget) {
      return this.redactorInputTarget.maxLength
    } else if (this.hasInputTarget) {
      return this.inputTarget.maxLength
    }
  }
}
