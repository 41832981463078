import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  // static targets = ['parentOrganization', 'plannableID', 'staffCount', 'companyName', 'planContainer']
  static targets = [
    "staffCount",
    "companyName",
    "companyNameLabel",
    "plannableId",
    "plannableType",
    "selectPlanButton",
    "plansContainer",
    "plansButton",
    "getInTouchContainer",
    "budgetContainer",
    "estimateContainer",
    "estimate",
    "includedParticipantCount",
    "additionalParticipantCount",
    "estimatedTotalCost",
    "durationType",
    "eventId",
    "payMethodContainer",
    "estimatedParticipantCount",
    "estimatedParticipantCountSource",
    "estimatedStaffCount",
    "promotionalCode",
  ];

  static values = {
    organization: Object,
    parent: Object,

    else: Object,
    estimateDisplayed: Boolean,
    confirmUrl: String,
  };

  connect() {
    this.selectedTypeButton = "plan_form_plannable_type_organization";
  }

  nameChanged(event) {
    this.plannableTypeSetter("name", event.currentTarget.value);
  }

  countChanged(event) {
    this.plannableTypeSetter("count", event.currentTarget.value);
    this.displayEstimatedParticipantCount(event.currentTarget);
  }

  promotionCodeChanged(event) {
    this.plannableTypeSetter("code", event.currentTarget.value);
    this.displayPromotionalCode(event.currentTarget);
    // this.displayEstimatedParticipantCount(event.currentTarget)
  }

  plannableChanged(event) {
    this.selectedTypeButton = event.currentTarget.id;
    let values = this.currentPlannableType();

    this.companyNameTarget.value = values.name;
    this.staffCountTarget.value = values.count;
    if (values.count == 0) {
      this.staffCountTarget.value = "";
    }

    if (values.plannable_id != undefined) {
      this.plannableIdTarget.value = values.plannable_id;
    }

    // Update Office/Organization/Workplace label
    this.companyNameLabelTarget.innerText = event.target.dataset.label;
  }

  estimatedParticipantsChanged(event) {
    event.preventDefault();
    this.estimatedParticipantCountTarget.value =
      this.estimatedParticipantCountSourceTarget.value;
    this.displayEstimate(event);
  }

  participantTypeChanged(event) {
    if (this.estimateDisplayedValue) {
      this.displayEstimate(event);
    }
  }

  currentPlannableType() {
    switch (this.selectedTypeButton) {
      case "plan_form_plannable_type_organization":
        return this.organizationValue;
        break;
      case "plan_form_plannable_type_parentorganization":
        return this.parentValue;
        break;
      default:
        return this.elseValue;
    }
  }

  plannableTypeSetter(key, value) {
    let typeObject = this.currentPlannableType();
    typeObject[key] = value;
    switch (this.selectedTypeButton) {
      case "plan_form_plannable_type_organization":
        this.organizationValue = typeObject;
        break;
      case "plan_form_plannable_type_parentorganization":
        this.parentValue = typeObject;
        break;
      default:
        this.elseValue = typeObject;
    }
  }

  showPlans(event) {
    event.preventDefault();
    this.toggleGetInTouch(event);
    this.plansContainerTarget.classList.remove("hidden");
    this.plansContainerTarget.scrollIntoView({ behavior: "smooth" });
  }

  toggleGetInTouch(event) {
    var shouldShowGetInTouch =
      parseInt(this.staffCountTarget.value) >=
      event.target.dataset.maxSelfServiceValue;
    if (shouldShowGetInTouch) {
      $(".plan-box .plan-details").hide();
      this.budgetContainerTarget.classList.add("hidden");
      this.estimateContainerTarget.classList.add("hidden");
      this.payMethodContainerTarget.classList.add("hidden");
      this.getInTouchContainerTarget.classList.remove("hidden");
    } else {
      $(".plan-box .plan-details").show();
      // this.budgetContainerTarget.classList.remove('hidden')
      this.getInTouchContainerTarget.classList.add("hidden");
    }
  }

  selectPlan(event) {
    event.preventDefault();

    this.plansButtonTargets.forEach((button) => {
      if (button.id == event.currentTarget.id) {
        button.classList.add("selected");
      } else {
        button.classList.remove("selected");
      }
    });
    this.durationTypeTarget.value = event.currentTarget.dataset.durationType;
    if (event.currentTarget.dataset.eventId) {
      this.eventIdTarget.value = event.currentTarget.dataset.eventId;
    }
    this.budgetContainerTarget.classList.remove("hidden");
    this.budgetContainerTarget.scrollIntoView({ behavior: "smooth" });
    if (this.estimateDisplayedValue) {
      this.displayEstimate(event);
    }
  }

  displayEstimate(event) {
    event.preventDefault();
    let form = event.target.form;
    let _this = this;

    fetch(form.action, {
      method: "POST",
      credentials: "same-origin",
      body: new FormData(form),
    })
      .then((response) => response.text())
      .then((html) => {
        _this.estimateContainerTarget.innerHTML = html;
        // if (!_this.estimateDisplayedValue) {
        // _this.estimateContainerTarget.scrollIntoView({behavior: 'smooth'})
        // }
        _this.includedParticipantCountTarget.value =
          _this.estimateTarget.dataset.includedParticipantCount;
        _this.additionalParticipantCountTarget.value =
          _this.estimateTarget.dataset.additionalParticipantCount;
        _this.estimatedTotalCostTarget.value =
          _this.estimateTarget.dataset.estimatedTotalCost;
        _this.estimateContainerTarget.classList.remove("hidden");
        _this.payMethodContainerTarget.classList.remove("hidden");
        _this.estimateDisplayedValue = true;
      });
  }

  displayPromotionalCode(element) {
    const url = element.dataset.codeUrl.replace(
      "promo_replace_value",
      encodeURIComponent(element.value)
    );
    var _this = this;
    fetch(url)
      .then((response) => response.text())
      .then((body) => {
        const json = JSON.parse(body);
        _this.promotionalCodeTarget.innerHTML = json.message;
      });
  }

  displayEstimatedParticipantCount(element) {
    console.log(element);
    const url = element.dataset.estimateUrl.replace(
      "est_replace_value",
      element.value
    );

    fetch(url)
      .then((response) => response.text())
      .then((body) => {
        const json = JSON.parse(body);
        this.estimatedStaffCountTarget.value = json.range;
      });
  }

  confirmPlan(event) {
    event.preventDefault();
    let form = event.target.form;
    let _this = this;

    fetch(event.target.form.dataset.confirmUrlValue, {
      method: "POST",
      credentials: "same-origin",
      body: new FormData(form),
    })
      .then((response) => response.text())
      .then((body) => {
        let json = JSON.parse(body);
        if (json.status == "ok") {
          window.location = json.redirect_url;
        } else if (json.status == "failed") {
          alert("Failed: " + json.message);
        }

        // _this.estimateContainerTarget.innerHTML = html
        // if (!_this.estimateDisplayedValue) {
        //   _this.estimateContainerTarget.scrollIntoView({behavior: 'smooth'})
        // }
        // _this.payMethodContainerTarget.classList.remove('hidden')
        // _this.estimateDisplayedValue = true
      });
  }
}
