import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tabItem", "tabsContent", "tabList"];

  connect() {}

  toggle(event) {
    event.preventDefault();
    const hash = new URL(event.target.href)?.hash?.substring(1);
    if (hash == undefined) {
      return;
    }
    this.clearActiveTabs();
    this.setActiveTab(hash);
    event.target.parentElement.classList.add("active");
  }

  clearActiveTabs() {
    this.tabsContentTarget.querySelectorAll(":scope > div").forEach((div) => {
      div.classList.remove("active");
    });

    this.tabListTarget.querySelectorAll(":scope > li").forEach((div) => {
      div.classList.remove("active");
    });
  }

  setActiveTab(name) {
    const tab = this.tabsContentTarget.querySelector(`div[id="${name}"]`);
    if (tab != undefined) {
      tab.classList.add("active");
    }
  }
}
