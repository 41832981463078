import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = [
		"name_size",
		"name_left",
		"name_top",
		"name_color",
		"frame"
	]

	connect() {
		console.log("preview init")
	}


	updatePreviews() {
		var frame = this.frameTarget
		var base_url = frame.attributes['data-src'].value
		var key = this.element.attributes['data-key'].value

		var full_url = base_url + 
			"&" + key + "_name_size=" + encodeURIComponent(this.name_sizeTarget.value) +
			"&" + key + "_name_left=" + encodeURIComponent(this.name_leftTarget.value) +
			"&" + key + "_name_top=" + encodeURIComponent(this.name_topTarget.value) +
			"&" + key + "_name_color=" + encodeURIComponent(this.name_colorTarget.value)
		frame.src = full_url

		console.log("updated previews")
	}
}