import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tabsContainer", "contentContainer"];
  static values = { skipHashUpdate: Boolean, scrollToContent: Boolean, nestingLevel: Number }

  connect() {
    // console.log("Tabs Controller - Welcome", window.location.hash);
    let specifiedTabs = window.location.hash.split("++");
    var specifiedTab = specifiedTabs[this.nestingLevelValue || 0];

    if(this.nestingLevelValue > 0) {
      specifiedTab = '#' + specifiedTab;
    }

    if (specifiedTab !== '#undefined' && specifiedTab !== "" && specifiedTab !== "#_=_") {
      this.selectNamedTab(specifiedTab, true);
    } else {
      // load first tab
      this.loadFirstTab();
    }
  }

  selectTab(event) {
    event.preventDefault();
    if (event.currentTarget.attributes.href === undefined) {
      return;
    }

    const name = event.currentTarget.attributes.href.value;

    if (this.skipHashUpdateValue !== true) {
      this.updateHash(name);
    }
    if (this.scrollToContentValue == true && window.visualViewport.height <= 736) {
      $('html, body').animate({scrollTop: $(this.contentContainerTarget).offset().top}, 1000)
    }
    this.selectNamedTab(name, false);
  }

  updateHash(name) {
    // Prevent chrome from jumping to anchor when we update the hash.
    var scroll = document.body.scrollTop;
    window.location.hash = name;
    document.body.scrollTop = scroll;
  }

  selectNamedTab(name, scroll) {
    this.clearActiveTabs();
    var activeTab = this.setActiveTab(name);
    this.setActiveContent(name);

    if (activeTab.getAttribute("data-load-path")) { this.loadTabData(activeTab); }

    if (scroll) {
      // timer needed because Chrome resets scroll after this line is executed :-(
      window.setTimeout(function () {
        activeTab.scrollIntoView();
      }, 200);
    }
  }

  loadFirstTab() {
    const element = this.element.querySelector('[data-toggle="tab"]')
    if (element) {
      this.selectNamedTab(element.attributes.href.value, false);
    }
  }

  clearActiveTabs() {
    this.element.querySelectorAll(".active").forEach(function (value, index, list) {
      value.classList.remove("active");
    });
  }

  setActiveTab(name) {
    var tab = this.element.querySelector('a[href="' + name + '"]');
    tab.parentElement.classList.add("active");
    return tab;
  }

  setActiveContent(name) {
    var id = name.replace("#", "");
    var content = document.getElementById(id);
    content.classList.add("active");
    window.loadTabContent(content);
  }

  loadTabData(element) {
    fetch(this.buildTabPath(element))
      .then((response) => response.text())
      .then((html) => {
        const id = element.attributes.href.value.replace("#", "")
        document.getElementById(id).innerHTML = html;

        if (loadPage !== "undefined") {
          loadPage();
        }
      });
    element.removeAttribute("data-load-path");
  }

  buildTabPath(element) {
    // this adds existing query params to the tab url
    const url = new URL(element.getAttribute("data-load-path"));
    const params = new URLSearchParams(window.location.search)
    params.forEach(function(value, key) {
      if (value) { url.searchParams.append(key, value) };
    });

    return url.pathname + url.search;
  }

}