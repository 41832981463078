import { Controller } from "@hotwired/stimulus"

// Connect the tinyPubSub 'event' to an event so it can be consumed by the Stimulus controller
window.calendarTripInsert = function(...args) {
  const event = document.createEvent("Events")
  event.initEvent("trip-insert", true, true)
  event.args = args
  window.dispatchEvent(event)
}
$.subscribe('trip:insert', calendarTripInsert)

export default class extends Controller {
  static targets = ["month", "name"]

  initialize() {
    this.refresh()
  }

  log(event) {
    console.log(this.monthElement.innerHTML)
  }

  refresh() {
    $('.popover-content-source').remove()
    this.toggleOverlay('hide')
    this.load(this.data.get("url"))
  }

  // Date handling

  toggleOverlay(state) {
    var body = $('body')
    if (state == 'hide') {
      body.removeClass('calendar-overlay-active')
    } else if (state == 'show') {
      body.addClass('calendar-overlay-active')
    } else {
      body.toggleClass('calendar-overlay-active')
    }
  }

  toggleDate(event) {
    var target = $(event.target).closest('li.date')

    $('.calendar .popped').each((index, element) => {
      element = $(element)
      if ( target.length == 0 || element[0].id != target[0].id) {
        element.popover('hide')
        this.toggleOverlay('hide')
        element.removeClass('popped')
      }
    })
    if (target.hasClass('disabled')) {
      return
    }
    // if (target.hasClass('populated')) {

    //   this.toggleOverlay('toggle')
    //   target.popover('toggle')
    //   target.toggleClass('popped')
    // } else {
      fetch(target.data('url'))
        .then(response => response.text())
        .then(html => {
          target.append(html)
          target.popover({
            title: target.find('.title-content')[0].textContent,
            content: target.find('.date-content'),
            html: true,
            placement: 'top',
            trigger: 'manual'
          })
          this.toggleOverlay('show')
          target.popover('show')
          target.addClass('populated popped')
        })
      // }
    // }
  }
  // Navigation

  get name() {
    return "November"
  }

  get monthElement() {
    return this.targets.find("month")
  }

  load(url) {
    fetch(url)
      .then(response => response.text())
      .then(html => {
        this.element.innerHTML = html
      })
  }

  nextMonth(event) {
    if (event.target.classList.contains("disabled")) {
      return
    }

    const url = this.element.firstElementChild.getAttribute('data-calendar-url-next')
    event.target.classList.add('disabled')
    this.load(url)
  }

  previousMonth(event) {
    if (event.target.classList.contains("disabled")) {
      return
    }

    const url = this.element.firstElementChild.getAttribute('data-calendar-url-previous')
    event.target.classList.add('disabled')
    this.load(url)
  }

  // Trip Edits
  editTrip(event) {
    var trip = $(event.target).closest('trip')
  }

  toggleNewTripForm(event) {
    $(event.target).next('.new-trip-form').toggleClass('hidden')
    $(event.target).toggleClass('hidden')
    $(event.target).closest('.popover').popover('update')
  }

  submitTripForm(event) {
    event.preventDefault()
    var form = $(event.target)

    fetch(
      form.attr('action'),
      {
        method: 'POST',
        credentials: 'same-origin',
        body: new FormData(event.target)
      }
    )
    .then(response => response.text())
    .then(html => {
      var dateEl = form.closest('.popover').prev('li.date')
      dateEl.replaceWith(html)
      form.closest('.popover').remove()
      // target.append(html)
      dateEl.popover({
        title: dateEl.find('.title-content')[0].textContent,
        content: dateEl.find('.date-content'),
        html: true,
        placement: 'top',
        trigger: 'manual'
      })
      dateEl.popover('show')
      dateEl.addClass('populated popped')
      this.toggleOverlay('hide')
      $.publish("trip:insert", {})
    })

  }

}
