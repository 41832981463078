import { Controller } from "@hotwired/stimulus";
import confetti from "canvas-confetti";

export default class extends Controller {
  static values = {
    url: String,
  };

  connect() {
    if (this.hasUrlValue) {
      this.replaceModalContent();
    }
  }

  close(event) {
    $(event.target.closest('.modal')).modal('hide')

    if (event.target.dataset.url) {
      window.location.href = event.target.dataset.url;
    } else if (event.target.dataset.buttonTagTarget) {
      $(`.nav-tabs a[href="#${event.target.dataset.buttonTagTarget}"]`).tab('show');
    }
  }

  loadConfetti() {
    const canvas = document.getElementById("background-confetti");
    canvas.width = canvas.parentElement.offsetWidth;
    canvas.height = canvas.parentElement.offsetHeight;

    canvas.confetti = confetti.create(canvas, { resize: true });

    canvas.confetti({
      particleCount: 250,
      spread: 90,
      origin: { y: 0.6 },
      gravity: 0.8,
    });
  }

  replaceModalContent() {

    fetch(this.urlValue)
      .then((r) => r.text())
      .then((html) => {
        if (html) {
          const controller = this;
          const container = controller.element;
          container.innerHTML = html;
          const el = $(container.getElementsByClassName("modal")[0]);
          el.modal("show");
          this.loadConfetti();
          el.on("hidden.bs.modal", function (e) {
            container.innerHTML = "";
            controller.replaceModalContent();
          });
        }
      });
  }
}
