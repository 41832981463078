import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  // static targets = ['heading', 'content', 'image', 'template', 'questionList', 'previewQuestions', 'questionTemplate']
  static targets = ['heading', 'image', 'template', 'questionList', 'previewQuestions', 'questionTemplate']

  connect() {
    // console.log(`loaded courses admin`);
  }

  updateImage(event) {
    let reader = new FileReader();
    reader.onload = (event) => {
      this.imageTarget.src = event.target.result;
    };

    reader.readAsDataURL(event.target.files[0]);
  }

  add_association(event) {
    event.preventDefault()
    let child_index = Math.floor(Math.random() * 20)
    var content = this.templateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, child_index)
    this.questionListTarget.insertAdjacentHTML('beforeend', content)

    let previewContent = this.questionTemplateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, child_index)
    this.previewQuestionsTarget.insertAdjacentHTML('beforeend', previewContent)
  }

  // cannot use targets in this function because this is fired off
  // from another controller. This method will not have access
  // to targets :(
  updateQuestionList(event) {
    let list = document.getElementsByClassName('preview_questions')[0];
    let moved_element = list.children[event.oldIndex]
    let original_element = list.children[event.newIndex]

    if (event.oldIndex > event.newIndex) {
      list.insertBefore(moved_element, original_element)
    } else {
      list.insertBefore(original_element, moved_element)
    }

    return true;
  }

  remove_association(event) {
    event.preventDefault()
    if (confirm("Are you sure?")) {
      let item = event.target.closest(".nested-fields")
      item.remove();
    }
  }

  selectonOnlyOneQuestion(event) {
    if (event.target.checked === false) { return }
    let list = document.getElementsByClassName('question-answer');
    Array.prototype.forEach.call(list,
                                 checkBox => { checkBox.checked = false; });
    event.target.checked = true;
  }

  updatePreview(event) {
    switch(event.target.dataset['previewTarget']) {
      case 'heading':
        this.headingTarget.textContent = event.target.value;
        break;
      default:
        console.log(`no target found for ${event.target.dataset['previewTarget']}`)
        console.log(event);
    }
  }
}