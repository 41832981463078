import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { modalContainer: String }

  connect() {
    $(this.modalContainerValue).addClass('modal fade')
  }

  show(event) {
    // Use dataset, as Stimulus values must (apparently) be on the element specified by the controller
    this.replaceModalContent(event.target.dataset.miniGalleryUrlValue)
    $(this.modalContainerValue).modal('show')
    $(this.modalContainerValue).on('hidden.bs.modal', function(e){
      $(this).empty()
    })
  }

  hide(event) {
    $(this.modalContainerValue).modal('hide')
  }

  replaceModalContent(url) {
    let controller = this
    fetch(url)
      .then((r) => r.text())
      .then((html) => {
        $(controller.modalContainerValue).html(html)
      });

  }


}