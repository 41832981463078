import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "goalType",
    "siteGoalId",
    "startDate",
    "startDateCalendar",
    "endDate",
    "endDateCalendar",
    "siteGoalStartDate",
    "siteGoalEndDate"
  ]

  connect() {
    console.log('loaded goal_form controller');
    this.backupTargetDates()
    if(this.goalTypeTarget.value == 'site_goal') {
      this.setAndDisableTargetDates()
    } else {
      this.enableTargetDates()
    }

  }

  backupTargetDates() {
    this.startDateTarget.dataset.origValue = this.startDateTarget.value
    this.endDateTarget.dataset.origValue = this.endDateTarget.value
  }

  restoreTargetDates() {
    this.startDateTarget.value = this.startDateTarget.dataset.origValue
    this.siteGoalStartDateTarget.value = this.startDateTarget.dataset.origValue
    this.endDateTarget.value = this.endDateTarget.dataset.origValue
  }

  setAndDisableTargetDates() {
    if(this.goalTypeTarget.dataset.hasOwnProperty('siteGoalStartDate')) {
      // Don't update the start date if it's not been saved.
      // siteGoalStartDateTarget refers to a hidden field which is submitted
      // even when the startDate field is disabled, due to it being a SiteGoal based goal.
      this.startDateTarget.value = this.goalTypeTarget.dataset.siteGoalStartDate
      this.siteGoalStartDateTarget.value = this.goalTypeTarget.dataset.siteGoalStartDate
    }
    this.startDateTarget.disabled = true
    this.startDateCalendarTarget.style.pointerEvents = 'none'

    if(this.goalTypeTarget.dataset.hasOwnProperty('siteGoalEndDate')) {
      // Don't update the end date if it's not been saved.
      // siteGoalEndDateTarget refers to a hidden field which is submitted
      // even when the endDate field is disabled, due to it being a SiteGoal based goal.
      this.endDateTarget.value = this.goalTypeTarget.dataset.siteGoalEndDate
      this.siteGoalEndDateTarget.value = this.goalTypeTarget.dataset.siteGoalEndDate
    }
    this.endDateTarget.disabled = true
    this.endDateCalendarTarget.style.pointerEvents = 'none'
  }

  enableTargetDates() {
    this.startDateTarget.disabled = false
    this.startDateCalendarTarget.style.pointerEvents = 'initial'

    this.endDateTarget.disabled = false
    this.endDateCalendarTarget.style.pointerEvents = 'initial'
  }

  goalTypeSelected(event) {
    if(this.hasSiteGoalIdTarget && this.hasGoalTypeTarget) {
      if(this.goalTypeTarget.value == 'site_goal') {
        // Setup the goal to follow the SiteGoal
        this.siteGoalIdTarget.value = this.goalTypeTarget.dataset.siteGoalId

        this.backupTargetDates()
        this.setAndDisableTargetDates()

      } else {
        this.siteGoalIdTarget.value = ''
        this.restoreTargetDates()
        this.enableTargetDates()
      }
    }
  }
}
