import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.localeSelector = document.getElementById("localeSelector");
    this.sitesDropdown = document.getElementById(
      "targeted_announcement_site_ids"
    );
    this.excludedSitesDropdown = document.getElementById(
      "targeted_announcement_excluded_site_ids"
    );
    this.chosenContainer = document.getElementById(
      "targeted_announcement_site_ids_chosen"
    );
    this.createButton = document.getElementById("js-ta-next");
    this.monitorTab = document.querySelector(".monitoring-tab");

    this.localeSelector.addEventListener("change", () => {
      this.handleLocaleChange();
      this.resetChosenSelections("targeted_announcement_site_ids_chosen");
      this.resetChosenSelections(
        "targeted_announcement_excluded_site_ids_chosen"
      );
      this.toggleSitesDropdown();
      this.updateButtonState();
    });

    const observer = new MutationObserver(() => this.updateButtonState());
    observer.observe(this.chosenContainer, { childList: true, subtree: true });
    this.toggleSitesDropdown();
    this.handleLocaleChange();
    this.updateButtonState();
  }

  handleLocaleChange() {
    const selectedLocale = this.localeSelector.value;
    this.filterSitesByLocale(selectedLocale, this.sitesDropdown);
    this.filterSitesByLocale(selectedLocale, this.excludedSitesDropdown);
  }

  // filter the sites by locale
  filterSitesByLocale(locale, dropdown) {
    if (dropdown && dropdown.options) {
      const options = dropdown.options;
      for (let i = 0; i < options.length; i++) {
        let optionLocale = options[i].getAttribute("data-locale");
        options[i].style.display =
          locale === "" || optionLocale === locale ? "block" : "none";
      }
      dropdown.dispatchEvent(new Event("chosen:updated"));
    }
  }

  // add tool tips and update events when toggled
  toggleSitesDropdown() {
    const enable = this.localeSelector.value !== "";
    this.sitesDropdown.disabled = !enable;
    this.excludedSitesDropdown.disabled = !enable;
    this.sitesDropdown.setAttribute(
      "data-placeholder",
      enable
        ? "Select your site(s)"
        : "Select a language before you can choose sites"
    );
    this.excludedSitesDropdown.setAttribute(
      "data-placeholder",
      enable
        ? "Select site(s) to exclude"
        : "Select a language before you can choose excluded sites"
    );
    this.sitesDropdown.dispatchEvent(new Event("chosen:updated"));
    this.excludedSitesDropdown.dispatchEvent(new Event("chosen:updated"));
  }

  // when selecting off a local, rest the input to default
  resetChosenSelections(chosenContainerId) {
    const closeButtons = document.querySelectorAll(
      "#" + chosenContainerId + " .search-choice-close"
    );
    closeButtons.forEach((button) => button.click());
  }

  // keep track of button validation if site is selected
  updateButtonState() {
    if (window.location.hash.includes("monitoring")) {
      this.monitorTab.classList.add("active");
    } else {
      const hasSelection =
        this.chosenContainer.getElementsByClassName("search-choice").length > 0;
      if (this.createButton) {
        if (hasSelection) {
          this.createButton.classList.remove("disabled");
          this.createButton.onclick = null;
          this.monitorTab.classList.remove("dn");
        } else {
          this.createButton.classList.add("disabled");
          this.createButton.onclick = function () {
            return false;
          };
          this.monitorTab.classList.add("dn");
        }
      }
    }
  }
}
