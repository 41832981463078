import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['eventUrl']

    initialize() {
        console.log("Hello from EventEncouragerStatsController")
    }

    eventSelected() {
        console.log("eventSelected()")
        var url = this.eventUrlTarget.value
        window.location = url
    }
}