import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["editor"]

  connect() {
    window.addEventListener("trix-change", this.trixChange.bind(this))
  }

  trixChange(event) {
    if (event.target == this.editorTarget && event.target.dataset['preview'] === 'true') {
      document.getElementById(`preview_${event.target.inputElement.id}`).innerHTML = event.target.value;
    }
  }
}