import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["container", "form", "body", "workgroup", "category"];
  static values = {
    url: String,
  };

  submit(event) {
    event.preventDefault();
    event.stopPropagation();
    const controller = this;

    if (this.categoryTarget.value == "workplace_bicycle_group") {
      this.toggle(event);
      return;
    }

    fetch(this.formTarget.action, {
      method: "POST",
      credentials: "same-origin",
      body: new FormData(this.formTarget),
    }).then((response) => {
      if (response.status == 200) {
        response.text().then((body) => {
          const json = JSON.parse(body);
          window.location = json.redirection;
        });
      } else {
        response.text().then((html) => {
          controller.bodyTarget.innerHTML = html;
        });
      }
    });
  }

  toggle(event) {
    event.preventDefault();
    this.formTarget.classList.toggle("hidden");
    this.workgroupTarget.classList.toggle("hidden");
  }

  show(event) {
    if (event != undefined) {
      event.preventDefault();
    }

    fetch(this.urlValue)
      .then((response) => response.text())
      .then((html) => {
        this.containerTarget.innerHTML = html;
        const modal = $(this.containerTarget.firstChild);
        modal.modal("show");
        modal.on("hidden.bs.modal", function (e) {
          $(this).empty();
        });
      });
  }
}
