import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.removeDuplicates();
  }

  removeDuplicates() {
    const siteOptions = this.element.querySelectorAll(".site-option");
    const seen = new Set();

    siteOptions.forEach((option) => {
      const optionText = option.textContent.trim();
      if (seen.has(optionText)) {
        option.parentElement.parentElement.remove();
      } else {
        seen.add(optionText);
      }
    });
  }
}
