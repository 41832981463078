import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["control"];

  static redactorConfig = {
    buttons: ["format", "bold", "italic", "ul", "ol"],
    formatting: ["p", "blockquote", "h2", "h3", "h4", "h5"],
    minHeight: "300px",
    plugins: ["alignment"],
  };

  connect() {
    if (this.hasControlTarget) {
      $(this.controlTarget).redactor(this.redactorConfig);
    }

    window.addEventListener("hashchange", () => {
      this.manageSideBar();
    });

    this.manageSideBar();
  }

  manageSideBar() {
    const tab = window.location.hash;

    if (tab == "#info") {
      document
        .getElementById("message_board_side_bar")
        .classList.remove("hidden");
      document.getElementById("side_bar_reporting").classList.add("hidden");
    } else if (tab == "#edit") {
      document.getElementById("message_board_side_bar").classList.add("hidden");
      document.getElementById("side_bar_reporting").classList.add("hidden");
    } else if (tab == "#reporting") {
      document.getElementById("message_board_side_bar").classList.add("hidden");
      document.getElementById("side_bar_reporting").classList.remove("hidden");
    }
  }
}
