import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.localeSelector = document.getElementById("localeSelector");
    this.sitesDropdown = document.getElementById("announcement_site_ids");
    this.excludedSitesDropdown = document.getElementById(
      "announcement_excluded_site_ids"
    );

    // if in edit mode get existing jQuery chosen value for sites / excluded
    if (window.location.href.includes("/edit")) {
      // re trigger the chosen plugin to register site selection
      this.sitesDropdown.dispatchEvent(new Event("chosen:updated"));
      this.excludedSitesDropdown.dispatchEvent(new Event("chosen:updated"));
      // hook into the event listener to handle any new change
      this.localeSelector.addEventListener("change", () =>
        this.handleLocaleChange()
      );
      //  on load if in edit, enable the site dropdowns
      this.sitesDropdown.disabled = false;
      this.excludedSitesDropdown.disabled = false;
      //  need to trigger jQuery chosen plugin again
      $(this.sitesDropdown).trigger("chosen:updated");
      $(this.excludedSitesDropdown).trigger("chosen:updated");
    } else {
      // else if new add event listned and init the handleChange locale
      if (this.localeSelector) {
        this.localeSelector.addEventListener("change", () =>
          this.handleLocaleChange()
        );
        this.handleLocaleChange();
      }
    }
  }

  handleLocaleChange() {
    const selectedLocale = this.localeSelector.value;
    this.filterSitesByLocale(selectedLocale, this.sitesDropdown);
    this.filterSitesByLocale(selectedLocale, this.excludedSitesDropdown);
    this.toggleSitesDropdown(selectedLocale);
    this.resetChosenSelections("announcement_site_ids_chosen");
    this.resetChosenSelections("announcement_excluded_site_ids_chosen");
  }

  filterSitesByLocale(locale, dropdown) {
    if (dropdown && dropdown.options) {
      const options = dropdown.options;
      for (let i = 0; i < options.length; i++) {
        let optionLocale = options[i].getAttribute("data-locale");
        options[i].style.display =
          locale === "" || optionLocale === locale ? "block" : "none";
      }
      dropdown.dispatchEvent(new Event("chosen:updated"));
    }
  }

  toggleSitesDropdown(locale) {
    const enable = locale !== "";
    this.sitesDropdown.disabled = !enable;
    this.excludedSitesDropdown.disabled = !enable;
    this.sitesDropdown.setAttribute(
      "data-placeholder",
      enable
        ? "Select your site(s)"
        : "Select a language before you can choose sites"
    );
    this.excludedSitesDropdown.setAttribute(
      "data-placeholder",
      enable
        ? "Select site(s) to exclude"
        : "Select a language before you can choose excluded sites"
    );
    this.sitesDropdown.dispatchEvent(new Event("chosen:updated"));
    this.excludedSitesDropdown.dispatchEvent(new Event("chosen:updated"));
  }

  resetChosenSelections(chosenContainerId) {
    const closeButtons = document.querySelectorAll(
      "#" + chosenContainerId + " .search-choice-close"
    );
    closeButtons.forEach((button) => button.click());
  }

  disconnect() {
    if (this.localeSelector) {
      this.localeSelector.removeEventListener(
        "change",
        this.handleLocaleChange
      );
    }
  }
}
