import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
  }

  deleteFeature(event) {
    // This action is on the form fieldset, not every individual .feature .controls element
    if (event.target.className == "controls") {

      if (
        event.target.parentElement.className.includes("feature") ||
        event.target.parentElement.className.includes("featurelist") ||
        event.target.parentElement.className.includes("signup-form") ||
        event.target.parentElement.className.includes("benefit")
      )
      {
        event.target.parentElement.remove()
      }
    }

  }

}