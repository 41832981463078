import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["editButton"]

    showModal(event) {
        event.preventDefault()
        var url = `/trip_editor/${event.currentTarget.dataset.tripId}/edit`
        fetch(url)
            .then(response => {
                status = response.status;
                return response;
            })
            .then(response => response.text())
            .then(html => {
                $('#tripEditorModal').html(html);
                $('#edit-trip-purpose-modal').modal()
            })
    }

    updateTrip(event) {
        event.preventDefault()
        var url = event.currentTarget.dataset.url

        fetch(url, {
            method: 'PUT',
            credentials: 'same-origin',
            body: new FormData(event.currentTarget.form)
        })
        .then(response => {
            // status = response.status;
            return response;
        })
        .then(response => response.json())
        .then(json => {
            var element = $(`#trip-item-${json.id}`)

            if(json.activity_type != "2"){
                // Don't want those nasty non-cycling trips; at this point it's been moved to UntrackedTrips on the server.
                $.publish("trip:delete", {})

                // Remove fom list
                element.fadeOut()
            } else {
                $.publish("trip:insert", {})
                $.publish("activity:insert", {})

                // Update purpose text
                element.find('.trip-purpose').html(json.display_purpose)
            }
            $('#edit-trip-purpose-modal').modal('hide')
        })
    }

    showPurposeInfo(event) {
        console.log("showPurposeInfo")
        event.preventDefault()
        var currentElement = $(event.currentTarget)
        currentElement.closest("p").find('.more-info').slideToggle()
    }
}