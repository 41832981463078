import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tabsContainer"];
  static values = {
    contentContainer: String,
    active: String,
  };

  initialize() {
    const tabs = this.element.querySelectorAll("li");
    tabs.forEach((tab) => {
      tab.setAttribute("data-action", "click->subnav-tabs#selectTab");
    });
  }

  connect() {
    this.contentContainer = document.getElementById(this.contentContainerValue);
    const specifiedTab = window.location.hash;

    if (
      specifiedTab !== "#undefined" &&
      specifiedTab !== "" &&
      specifiedTab !== "#_=_"
    ) {
      this.selectNamedTab(specifiedTab, true);
    } else {
      // load first tab
      this.loadFirstTab();
    }
  }

  loadFirstTab() {
    const element = this.element.querySelector("li");
    if (element) {
      this.selectNamedTab(
        element.firstElementChild.attributes.href.value,
        false
      );
    }
  }

  selectTab(event) {
    event.preventDefault();
    const name = event.currentTarget.firstElementChild.attributes.href.value;

    if (name === undefined) {
      return;
    }

    this.updateHash(name);

    if (
      this.scrollToContentValue == true &&
      window.visualViewport.height <= 736
    ) {
      $("html, body").animate(
        { scrollTop: $(this.contentContainer).offset().top },
        1000
      );
    }
    this.selectNamedTab(name, false);
  }

  selectNamedTab(name, scroll) {
    this.clearActiveTabs();
    var activeTab = this.setActiveTab(name);
    this.setActiveContent(name);

    if (activeTab.getAttribute("data-load-path")) {
      this.loadTabData(activeTab);
    }

    if (scroll) {
      // timer needed because Chrome resets scroll after this line is executed :-(
      window.setTimeout(function () {
        activeTab.scrollIntoView();
      }, 200);
    }
  }

  clearActiveTabs() {
    this.element
      .querySelectorAll(".active")
      .forEach(function (value, index, list) {
        value.classList.remove("active");
      });

    this.contentContainer
      .querySelectorAll(".active")
      .forEach(function (value, index, list) {
        value.classList.remove("active");
      });
  }

  setActiveTab(name) {
    var tab = this.element.querySelector('a[href="' + name + '"]');
    tab.parentElement.classList.add("active");
    return tab;
  }

  setActiveContent(name) {
    var content = this.contentContainer.querySelector(name);
    content.classList.add("active");
    window.loadTabContent(content);
  }

  updateHash(name) {
    // Prevent chrome from jumping to anchor when we update the hash.
    var scroll = document.body.scrollTop;
    window.location.hash = name;
    document.body.scrollTop = scroll;
  }

  loadTabData(element) {
    fetch(this.buildTabPath(element))
      .then((response) => response.text())
      .then((html) => {
        this.contentContainer.querySelector(
          element.attributes.href.value
        ).innerHTML = html;
      });
    element.removeAttribute("data-load-path");
  }

  buildTabPath(element) {
    // this adds existing query params to the tab url
    const url = new URL(element.getAttribute("data-load-path"));
    const params = new URLSearchParams(window.location.search);
    params.forEach(function (value, key) {
      if (value) {
        url.searchParams.append(key, value);
      }
    });

    return url.pathname + url.search;
  }
}
