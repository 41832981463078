import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['siteContainer'];

	load(event) {
		event.preventDefault();
    this.hideContainers();

    const siteId = event.target.parentElement.id.substring(4)
    const siteContainer = this.siteContainerTargets.find( ({ id }) => id.substring(10) == siteId)
    const table =  siteContainer.getElementsByTagName('table')[0];
    siteContainer.classList.remove('hidden')

    if (siteContainer.dataset.loaded == 'true') { return; }

    siteContainer.dataset.loaded = 'true'
    const url = this.buildUrl(table.parentElement.dataset['loadMoreUrl'])

		fetch(url)
			.then(response => {
				status = response.status;
				return response;
			})
			.then(response => response.text())
			.then(html => {
        table.getElementsByTagName('tbody')[0].insertAdjacentHTML('beforeend', html);

				// Rails can return a status 203 (:partial_content) to leave the load more button in place
				// A status of 200 (:ok) means we're done, with no more pages of results to follow
				if (status == '200') {
          siteContainer.querySelector('.load-more-teams').classList.add('hidden');
				}
			});
	}

  hideContainers() {
    this.siteContainerTargets.forEach((container) => { container.classList.add('hidden') });
  }

  buildUrl(url) {
    let baseURL = new URL(url, document.location.origin);
    const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());
		for (const [key, value] of Object.entries(params)) {
			if (value != '' && key != 'offset') { baseURL.searchParams.append(key, value); }
		}

		return baseURL;
  }
}