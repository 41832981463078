import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['container']
  static values = {
    url: String,
    startOpen: String
  }

  connect() {
    $(this.containerTarget).addClass('modal fade')

    let params = (new URL(document.location)).searchParams;
    let name = params.get(this.startOpenValue)
    if (name != null || this.startOpenValue == 'true') {
      this.show(null);
    }

    return true
  }

  show(event) {
    if (event != null) {
      event.preventDefault()
    }

    // allow defining the url on the click element to handle modals in a list
    if (event != null && event.target.dataset.url !== undefined) {
      history.pushState({modal_open: this.containerTarget.id}, '', event.target.href)
      this.replaceModalContent(event.target.dataset.url)
    } else if (this.urlValue) { // Use dataset, as Stimulus values must (apparently) be on the element specified by the controller
      if (event != null) {
        history.pushState({modal_open: this.containerTarget.id}, '', event.target.href)
      }
      this.replaceModalContent(this.urlValue)
    } else {
      $(this.containerTarget).modal('show')
      $(this.containerTarget).on('hidden.bs.modal', function(e){
        // $(this).empty()
      })
    }

  }

  hide(event) {
    if(history.state == {modal_open: this.containerTarget.id} ) {
      history.back()
    }


    this.containerTarget.modal('hide')
  }

  replaceModalContent(url) {
    let controller = this
    fetch(url)
      .then((r) => r.text())
      .then((html) => {
        let el = $(controller.containerTarget)
        el.html(html)
        el.modal('show')
        el.on('hidden.bs.modal', function(e){
          $(this).empty()
        })

      });

  }


}