import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['photosContainer', 'loadButton'];

  connect() {
  }

  load(event) {
    event.preventDefault();

    let url = new URL(this.data.get('url'), document.location.origin);
    url.searchParams.append('offset', this.offsetRows());
    url.searchParams.append('last_photo_id', parseInt(this.lastPhotoId()));
    url.searchParams.append('title_count', document.getElementsByClassName('photos-section-title').length)

    let that = this
    fetch(url)
      .then(response => {
        status = response.status;
        return response;
      })
      .then(response => response.text())
      .then(html => {
        that.photosContainerTarget.insertAdjacentHTML('beforeend', html);

        // Rails can return a status 203 (:partial_content) to leave the load more button in place
        // A status of 200 (:ok) means we're done, with no more pages of results to follow
        if (status == '200') {
          that.loadButtonTarget.classList.add('hidden');
        }
      });
  }

  // append query params that are not blank
  appendQueryParams(baseURL)  {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    for (const [key, value] of Object.entries(params)) {
      if (value != '' && key != 'offset') { baseURL.searchParams.append(key, value); }
    }

    return baseURL;
  }

  offsetRows() {
    return this.photosContainerTarget.getElementsByClassName('photo').length;
  }

  lastPhotoId() {
    let elements = this.photosContainerTarget.getElementsByClassName('photo-container')
    return elements[elements.length - 1].dataset.id
  }
}
