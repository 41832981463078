import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tabItem", "reportPartial"];
  // static values = { url: String } -- for when we upgrade

  connect() {
    // if thte about anchor is provided show about tab instead of report tab
    const tabID =
      window.location.hash === "#about" ? "0" : this.tabItemTargets[0].id;
    this.loadedReports = ["0"];
    this.setTabAndLoadReport(tabID);
  }

  selectTab(event) {
    event.preventDefault();
    this.setTabAndLoadReport(event.target.parentElement.id);
  }

  setTabAndLoadReport(reportId) {
    this.clearActiveTabs();
    this.clearActiveReports();
    this.setActiveTab(reportId);
    this.loadReport(reportId);
  }

  loadReport(reportId) {
    const reportPartial = this.reportPartialTargets.find(
      ({ id }) => id === `report-${reportId}`
    );

    if (this.loadedReports.includes(reportId)) {
      reportPartial.classList.remove("hidden");
      return;
    }

    this.loadedReports.push(reportId);
    const url = this.data.get("url-value").replace("replace_id", reportId);

    fetch(url)
      .then((response) => response.text())
      .then((html) => {
        reportPartial.innerHTML = html;
        reportPartial.classList.remove("hidden");
      });
  }

  clearActiveTabs() {
    this.tabItemTargets.forEach((tab) => {
      tab.classList.remove("active");
    });
  }

  clearActiveReports() {
    this.reportPartialTargets.forEach((report) => {
      report.classList.add("hidden");
    });
  }

  setActiveTab(name) {
    const li = this.tabItemTargets.find(({ id }) => id === name);

    if (li != undefined) {
      li.classList.add("active");
    }
  }
}
