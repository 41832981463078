// csv_progress_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "output" ]

  connect() {
    console.log("CSV Progress!", this.element)

    if (this.data.has("refreshInterval")) {
      this.startRefreshing()
    }
  }

  startRefreshing() {
  	console.log("startRefreshing")
    const intervalId = setInterval(() => {
      this.load()
    }, this.data.get("refreshInterval"))

    this.data.set("refreshIntervalId", intervalId)
  }

  load() {
  	console.log("load")
  	var controller = this
    fetch(this.data.get("url"))
      .then(response => {
      	if(response.status == 201) {
      		const refreshIntervalId = parseInt(controller.data.get("refreshIntervalId"))
      		clearInterval(refreshIntervalId)
      		
      		controller.data.delete("refreshInterval")
      		controller.data.delete("refreshIntervalId")
      	}
      	return response.text()
      })
      .then(html => {
      	const element = this.outputTarget
        element.innerHTML = html
      })
  }

}